export class CompanyOptions {
  constructor(
    public deliveryWindow: string,
    public pickupInfos: PickupInfoOptions = null,
    public requiredFields: RequiredFields,
    public serviceTypes: ServiceType[]
  ) {}

  public static fromJson(json: Object): CompanyOptions {
    var pickupInfos = PickupInfoOptions.fromJson(json["pickupInfos"]);
    var requiredFields = RequiredFields.fromJson(json["requiredFields"]);
    var serviceTypes = ServiceType.fromJson(json["serviceTypes"]);
    return new CompanyOptions(
      json["deliveryWindow"],
      pickupInfos,
      requiredFields,
      serviceTypes
    );
  }
  public static toJson(companyOptions: CompanyOptions): Object {
    return JSON.stringify(companyOptions);
  }
}

export class PickupInfoOptions {
  constructor(
    public name: string,
    public address: string,
    public state: string,
    public complement: string,
    public phone: string,
    public city: string,
    public zip: string,
    public see: string
  ) {}

  public static fromJson(json: Object): PickupInfoOptions {
    return new PickupInfoOptions(
      json["name"],
      json["address"],
      json["state"],
      json["complement"],
      json["phone"],
      json["city"],
      json["zip"],
      json["see"]
    );
  }
  public static toJson(pickupInfoOptions: PickupInfoOptions): Object {
    return JSON.stringify(pickupInfoOptions);
  }
}

export class RequiredFields {
  constructor(
    public nameDelivery: number,
    public phoneDelivery: number,
    public emailDelivery: number,
    public complementDelivery: number,
    public addressDelivery: number,
    public zipDelivery: number,
    public cityDelivery: number,
    public stateDelivery: number,
    public seeDelivery: number,
    public noteDelivery: number,
    public pieces: number,
    public weight: number,
    public orderDescription: number
  ) {}

  public static fromJson(json: Object): RequiredFields {
    return new RequiredFields(
      json["nameDelivery"],
      json["phoneDelivery"],
      json["emailDelivery"],
      json["complementDelivery"],
      json["addressDelivery"],
      json["zipDelivery"],
      json["cityDelivery"],
      json["stateDelivery"],
      json["seeDelivery"],
      json["noteDelivery"],
      json["pieces"],
      json["weight"],
      json["orderDescription"]
    );
  }
  public static toJson(requiredFields: RequiredFields): Object {
    return JSON.stringify(requiredFields);
  }
}

export class ServiceType {
  constructor(
    public title: string,
    public enable: boolean,
    public label: string,
    public duration: number
  ) {}

  public static fromJson(json: any): ServiceType[] {
    var servicetypes: ServiceType[] = [];
    json.forEach(element => {
      servicetypes.push(
        new ServiceType(
          element["title"],
          element["enable"],
          element["label"],
          parseInt(element.duration, 10)
        )
      );
    });
    return servicetypes;
  }
  public static toJson(serviceType: ServiceType): Object {
    return JSON.stringify(serviceType);
  }
}
