import { NgModule, NO_ERRORS_SCHEMA } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";

import { TrackingOrderPageComponent } from "./tracking-order-page/tracking-order-page.component";
import { DetailsOrderPageComponent } from "./details-order-page/details-order-page.component";
import { HeaderComponent } from "./header/header.component";
import { FormOrderComponent } from "./new-order-page/form-order/form-order.component";
import { EcourierModal } from "./new-order-page/ecourier-modal/ecourier-modal";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NewOrderPageComponent } from "./new-order-page/new-order-page.component";
import { SuccessOrderPageComponent } from "./new-order-page/success-order/success-order-page.component";

import { MatButtonModule } from "@angular/material/button";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatInputModule } from "@angular/material/input";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { FeaturesModule } from "@app/shared/_features/features.module";
import { MatDialogModule } from "@angular/material/dialog";
import { MatTableModule } from "@angular/material/table";
import { MatSnackBarModule, MatSnackBar } from "@angular/material/snack-bar";
import { SearchTableOrdersComponent } from "./tracking-order-page/search-table-orders/search-table-orders.component";
import { DetailsOrderFormComponent } from "./tracking-order-page/details-order-form/details-order-form.component";
import { ResetPasswordPageComponent } from "./reset-password-page/reset-password-page.component";
import { MatDatepickerModule } from "@angular/material/datepicker";
//TODO
import {
  SatDatepickerModule,
  SatNativeDateModule,
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE
} from "saturn-datepicker";
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter
} from "@angular/material-moment-adapter";
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {MatIconModule} from '@angular/material/icon';
const MaterialComponents = [
  MatButtonModule,
  MatGridListModule,
  MatInputModule,
  MatToolbarModule,
  MatFormFieldModule,
  MatSelectModule,
  MatDialogModule,
  MatSnackBarModule,
  MatTableModule,
  MatDatepickerModule
];

@NgModule({
  declarations: [
    NewOrderPageComponent,
    SuccessOrderPageComponent,
    TrackingOrderPageComponent,
    DetailsOrderPageComponent,
    HeaderComponent,
    FormOrderComponent,
    EcourierModal,
    SearchTableOrdersComponent,
    DetailsOrderFormComponent,
    ResetPasswordPageComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    BrowserAnimationsModule,
    MaterialComponents,
    RouterModule,
    FeaturesModule,
    ReactiveFormsModule,
    SatDatepickerModule,
    SatNativeDateModule,
    NgxMaterialTimepickerModule,
    MatIconModule
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }
  ],
  entryComponents: [EcourierModal],
  exports: [FormOrderComponent],
  schemas: [NO_ERRORS_SCHEMA]
})
export class FeaturesFrontModule {}
