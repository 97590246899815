import { Company } from "./company.model";
import { pickupContact } from "./pickupContact.model";

export class AddresPickUp {
  constructor(
    public name: string,
    public phone: string,
    public address: string,
    public zip: string,
    public city: string,
    public state: string,
    public see = "",
    public complement = "",
    public country = "",
    public note = "",
    public companyId: string = "",
    public Contact: pickupContact = null,
  ) {}

    public static fromJson(json: Object): AddresPickUp {
    const jsonContact: Object = json["contact"];
    //company data
    const company: Company = new Company(
      jsonContact["userId"],
      jsonContact["name"],
      jsonContact["isLoggedEcourier"]
    );
    //address contact data
    const contact: pickupContact = new pickupContact(
      jsonContact["siteId"],
      jsonContact["customercode"],
      jsonContact["phone"],
      jsonContact["salespersonId"],
      jsonContact["contact"],
      jsonContact["email"],
      company
    );

    return new AddresPickUp(
      "TODO",
      "TODO",
      json["address"],
      json["zip"],
      json["city"],
      json["state"],
      "TODO",
      "TODO",
      "TODO",
      "TODO",
      json["companyId"],
      contact
    );
  }

  public static fromOrderJson(json: any): AddresPickUp {
    return new AddresPickUp(
      json.Name,
      json.Phone,
      json.Address,
      json.Zip,
      json.City,
      json.State,
      json.See,
      json.Room,
      json.Country,
      json.Note
    );
  }
  public static toJson(address: AddresPickUp): Object {
    return JSON.stringify(address);
  }
}
