<h1 mat-dialog-title class="title">Ecourier Credentials</h1>
<div class="fields-container-Ecourier">
  <mat-form-field>
    <mat-label>Login</mat-label>
    <input matInput type="text" [(ngModel)]="data.login" />
  </mat-form-field>
  <mat-form-field>
    <mat-label>Password</mat-label>
    <input matInput type="password" [(ngModel)]="data.password" />
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button
    mat-raised-button
    color="primary"
    [mat-dialog-close]="data"
    cdkFocusInitial
  >
    Connect
  </button>
</div>
