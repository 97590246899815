import {Component, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {FormBuilder, FormGroup, Validators, FormArray} from "@angular/forms";
import {Company} from "@app/shared/_models/company.model";
import {Subscription} from "rxjs";
import {AccountAndCompaniesService} from "@app/shared/_services/accountAndCompanies/account-and-companies.service";
import {UserAccount} from "@app/shared/_models/userAccount.model";
import {
  CompanyOptions, RequiredFields,
  ServiceType
} from "@app/shared/_models/companyOptions.model";
import Utils from "@app/shared/utils/utils";
import {AddressInfo} from "@models/googleService.model";
import {debounceTime} from "rxjs/operators";
import {AutocompleteService} from "@app/shared/autocomplete/autocomplete.service";

@Component({
  selector: "app-manage-account",
  templateUrl: "./manage-account.component.html"
})
export class ManageAccountComponent implements OnInit {
  public userIdParam: string;
  public currentCompanyId: string;
  public formClient: FormGroup;
  public companies: Company[];
  public companieOptions: CompanyOptions;
  public newCompanieOptions: CompanyOptions;
  public user: UserAccount;
  public userName: string;
  public optionsAddress: any[];
  public isCurrentCompany: boolean;
  public companiesSub: Subscription;
  public currentCompany: Company;
  public windowTimes: Array<string> = [
    "1 MINUTE",
    "5 MINUTES",
    "10 MINUTES",
    "15 MINUTES",
    "30 MINUTES",
    "1 HOUR",
    "2 HOUR-ALL",
    "2 HOUR-EVEN",
    "2 HOUR-ODD"
  ];
  public isUpdating: boolean = false;
  public error: string;

  constructor(
    private router: Router,
    private routerActive: ActivatedRoute,
    private fb: FormBuilder,
    private accountCompService: AccountAndCompaniesService,
    private autocompleteService: AutocompleteService
  ) {
    this.formClient = this.fb.group({
      deliveryWindow: [null, [Validators.required]],
      pickupInfo: this.fb.group({
        name: [null, [Validators.required]],
        address: [null, [Validators.required]],
        state: [
          null,
          [Validators.required, Validators.pattern(Utils.stateRegexPattern)]
        ],
        complement: [null],
        phone: [
          null,
          [Validators.required, Validators.pattern(Utils.phoneRegexPattern)]
        ],
        city: [null, [Validators.required]],
        zip: [
          null,
          [Validators.required, Validators.pattern(Utils.zipRegexPattern)]
        ],
        see: [null]
      }),
      serviceTypes: this.fb.array([]),
      requiredFields: this.fb.group({
        nameDelivery: [false, [Validators.required]],
        phoneDelivery: [false, [Validators.required]],
        emailDelivery: [false, [Validators.required]],
        complementDelivery: [false, [Validators.required]],
        addressDelivery: [true, [Validators.required]],
        zipDelivery: [true, [Validators.required]],
        cityDelivery: [true, [Validators.required]],
        stateDelivery: [true, [Validators.required]],
        seeDelivery: [false, [Validators.required]],
        noteDelivery: [false, [Validators.required]],
        pieces: [false, [Validators.required]],
        weight: [false, [Validators.required]],
        orderDescription: [0, [Validators.required]]
      })
    });

    this.formClient.get("pickupInfo").get("address").valueChanges.subscribe((data) => {
      this.getAddress(data);
    });
  }

  ngOnInit(): void {
    this.userIdParam = this.routerActive.snapshot.queryParams.userId;
    this.currentCompanyId = this.routerActive.snapshot.queryParams.companyId;
    this.accountCompService.getCompaniesFromAccountId(this.userIdParam);
    this.companiesSub = this.accountCompService.Companies.subscribe(
      companies => {
        this.companies = companies;
        this.isCurrentCompany = true;

        if (this.currentCompanyId && this.companies) {
          for (const company of this.companies) {
            if (company.companyId === this.currentCompanyId) {
              this.selectedCompany(company);
            }
          }
        }
      },
      err => console.error(err)
    );
    this.getUserAccountData();
    setTimeout(() => this.formClient.disable()); //bug Angular we have to do that for initialize the first time
    this.isUpdating = false;
  }

  get serviceTypesArray() {
    return this.formClient.get("serviceTypes") as FormArray;
  }

  resetServicesTypeArray(currentServArray: FormArray) {
    if (currentServArray.controls.length > 0) {
      currentServArray.clear();
    }
  }

  getUserAccountData() {
    if (this.userIdParam) {
      this.accountCompService
        .setPathUser()
        .getUserAccountData(this.userIdParam)
        .subscribe(
          userData => {
            this.user = userData;
            this.userName = userData.firstName + " " + userData.lastName;
          },
          err => console.error(err)
        );
    }
  }

  goToModifyUserAccount() {
    this.router.navigate([`createAccount`, this.userIdParam], {
      queryParamsHandling: "preserve",
      preserveFragment: true
    });
  }

  selectedCompany(company: Company) {
    this.currentCompanyId = company.companyId;
    this.currentCompany = company;
    this.resetServicesTypeArray(this.serviceTypesArray);

    this.formClient.reset();
    this.initSelectedCompanyFromCompaniesOptions(company.companyId);
  }

  initSelectedCompanyFromCompaniesOptions(selectedCompId: string): void {
    this.companies.forEach(companyOption => {
      if (companyOption.companyId === selectedCompId) {
        this.companieOptions = companyOption.options;
        if (!this.companieOptions.pickupInfos) {
          this.setFormNewClient();
        } else {
          this.setForm();
        }
      }
    });
  }

  setForm() {
    this.formClient.patchValue({
      deliveryWindow: this.companieOptions.deliveryWindow,
      serviceTypes: new Array()
    });
    this.formClient.get("pickupInfo").patchValue({
      address: this.companieOptions.pickupInfos.address,
      name: this.companieOptions.pickupInfos.name,
      city: this.companieOptions.pickupInfos.city,
      complement: this.companieOptions.pickupInfos.complement,
      phone: this.companieOptions.pickupInfos.phone,
      see: this.companieOptions.pickupInfos.see,
      state: this.companieOptions.pickupInfos.state,
      zip: this.companieOptions.pickupInfos.zip
    });
    this.formClient.get("requiredFields").patchValue({
      addressDelivery: true,
      cityDelivery: true,
      complementDelivery: this.companieOptions.requiredFields
        .complementDelivery,
      emailDelivery: this.companieOptions.requiredFields.emailDelivery,
      nameDelivery: this.companieOptions.requiredFields.nameDelivery === null ? 0 : this.companieOptions.requiredFields.nameDelivery,
      noteDelivery: this.companieOptions.requiredFields.noteDelivery,
      phoneDelivery: this.companieOptions.requiredFields.phoneDelivery,
      pieces: this.companieOptions.requiredFields.pieces,
      seeDelivery: this.companieOptions.requiredFields.seeDelivery,
      stateDelivery: true,
      weight: this.companieOptions.requiredFields.weight,
      zipDelivery: true,
      orderDescription: this.companieOptions.requiredFields.orderDescription === undefined ?
        true : this.companieOptions.requiredFields.orderDescription
    });
    if (this.companieOptions.serviceTypes?.length > 0) {
      const fa = this.formClient.get("serviceTypes") as FormArray;
      this.companieOptions.serviceTypes.forEach(service => {
        if (service.title != null) {
          fa.push(
            this.fb.group({
              title: service.title,
              enable: service.enable,
              label: {value: service.label, disabled: true},
              duration: service.duration
            })
          );
        }
      });
    }
  }

  setFormNewClient() {
    this.formClient.patchValue({
      deliveryWindow: this.companieOptions.deliveryWindow,
      serviceTypes: []
    });
    this.formClient.get("pickupInfo").patchValue({
      address: "",
      name: "",
      city: "",
      complement: "",
      phone: "",
      see: "",
      state: "",
      zip: ""
    });
    this.formClient.get("requiredFields").patchValue({
      ...this.evaluateRequiredFields(this.companieOptions.requiredFields, true),
      addressDelivery: true,
      cityDelivery: true,
      stateDelivery: true,
      zipDelivery: true
    });

    if (this.companieOptions.serviceTypes?.length > 0) {
      const fa = this.formClient.get("serviceTypes") as FormArray;
      this.companieOptions.serviceTypes.forEach(service => {
        fa.push(
          this.fb.group({
            title: service.title,
            enable: service.enable,
            label: service.label,
            duration: service.duration
          })
        );
      });
    }
  }

  addressSelected(option) {
    this.autocompleteService.getAddressInfo(option.place_id).subscribe((res: any) => {
      const address: Partial<AddressInfo> = this.autocompleteService.getAddressFromGoogleObject(res.data.address_components);
      this.formClient.get("pickupInfo").patchValue({
        address: address.street_number + " " + address.route,
        zip: address.postal_code,
        city: address.locality,
        state: address.administrative_area_level_1
      });
    });
  }

  getAddress(input: string) {
    if (input !== "") {
      this.autocompleteService.getAddress(input)
        .pipe(debounceTime(100))
        .subscribe((data) => {
          this.optionsAddress = data;
        });
    }
  }


  setmodifyClient() {
    this.formClient.enable();
    this.formClient.get("requiredFields").get("addressDelivery").disable();
    this.formClient.get("requiredFields").get("cityDelivery").disable();
    this.formClient.get("requiredFields").get("stateDelivery").disable();
    this.formClient.get("requiredFields").get("zipDelivery").disable();
    this.isUpdating = true;
  }

  submitClientForm() {
    if (this.formClient.valid) {
      this.buildData();
      this.accountCompService
        .putCompanieOptionsByCompanyId(
          this.currentCompany.companyId,
          this.newCompanieOptions
        )
        .subscribe(
          () => {
            this.resetCurrentCompanieOption();
          },
          () => {
            this.setForm();
            this.error =
              "Submit fail, this user already exist, thank you to try again later.";
          }
        );
    }
  }

  resetCurrentCompanieOption() {
    this.companieOptions = this.newCompanieOptions;
    this.formClient.disable();
    this.isUpdating = false;
    this.accountCompService.getCompaniesFromAccountId(this.userIdParam);
  }

  buildData() {
    this.newCompanieOptions = {
      deliveryWindow: this.formClient.get("deliveryWindow").value,
      pickupInfos: {
        address: this.formClient.get("pickupInfo").get("address").value,
        name: this.formClient.get("pickupInfo").get("name").value,
        city: this.formClient.get("pickupInfo").get("city").value,
        complement: this.formClient.get("pickupInfo").get("complement").value,
        phone: this.formClient.get("pickupInfo").get("phone").value,
        see: this.formClient.get("pickupInfo").get("see").value,
        state: this.formClient.get("pickupInfo").get("state").value,
        zip: this.formClient.get("pickupInfo").get("zip").value
      },
      requiredFields: this.evaluateRequiredFields(this.formClient.get("requiredFields").value, false) as RequiredFields,
      serviceTypes: []
    };

    const fa = this.formClient.get("serviceTypes") as FormArray;
    fa.value.forEach(service => {
      var serviceType: ServiceType = {
        duration: service.duration,
        enable: service.enable,
        label: service.label,
        title: service.title
      };
      this.newCompanieOptions.serviceTypes.push(serviceType);
    });
  }

  evaluateRequiredFields(object: RequiredFields, reverse: boolean): Partial<RequiredFields> {
    const requiredFieldsEvaluated: Partial<RequiredFields> = {};
    for (const value in object) {
      if (reverse === false) {
        requiredFieldsEvaluated[value] = object[value] === null ? 0 : object[value];
      } else {
        requiredFieldsEvaluated[value] = object[value] === 0 ? null : object[value];
      }
    }
    return requiredFieldsEvaluated;
  }

  cancel() {
    this.formClient.disable();
    this.isUpdating = false;
    this.selectedCompany(this.currentCompany); //reset data as selected
  }
}
