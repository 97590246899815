import { Component, OnInit, ViewChild, OnDestroy } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { Router, ActivatedRoute } from "@angular/router";
import { MatPaginator } from "@angular/material/paginator";
import { AdminService } from "@app/back/_services/admin.service";
import { UserRowDataTable } from "@app/back/_models/user-row-data-table";
import {of} from "rxjs";
import {delay} from "rxjs/operators";

@Component({
  selector: "app-search-create-users",
  templateUrl: "./search-create-users.component.html",
  styleUrls: ["./search-create-user.component.scss"]
})
export class SearchCreateUsersComponent implements OnDestroy, OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  public displayedColumns = ["companyName", "status", "createdAt", "userName"];
  public dataSource: any;
  public searchedValue = "";
  public obs$ = null;

  private tmpAllTableArray: UserRowDataTable[];
  private numberRowToDisplay = 2;
  private currentIndexStop: number;
  private fragment: string;

  constructor(private router: Router, private activeRoute: ActivatedRoute, private adminService: AdminService) {}
  ngOnInit(): void {
    this.adminService.dataTable.subscribe((data: UserRowDataTable[]) => {
      if (data) {
        this.tmpAllTableArray = data;
        this.dataSource = new MatTableDataSource(
          this.handleSizeRowTable(false)
        );
      }
      this.isDataForTableFounded();
    });
    this.activeRoute.fragment.subscribe(fragment => { this.fragment = fragment; });
  }
  handleSizeRowTable(fromSeeMore: boolean): UserRowDataTable[] {
    this.numberRowToDisplay = fromSeeMore
      ? this.numberRowToDisplay + this.numberRowToDisplay
      : this.numberRowToDisplay;
    let i = 0;
    const tmp = this.tmpAllTableArray.filter(user => {
      if (i < this.numberRowToDisplay) {
        i = i + 1;
        return user;
      }
    });
    this.currentIndexStop = i;
    this.isMoreToSee();
    return tmp;
  }
  onSearch() {
    this.adminService.searchUsersAndCompaniesForDataTable(this.searchedValue);
    this.router.navigate( ["/homeAdmin"], {fragment: "results"} );
    this.obs$ = of("ing...");

    const self = this;
    // tslint:disable-next-line:only-arrow-functions
    setTimeout(function() {
      try {
        document.querySelector("#" + self.fragment).scrollIntoView();
        self.obs$ = of("");
      } catch (e) { console.error(e); }
    }, 1200);
  }
  // check Displays
  isDataForTableFounded(): boolean {
    return this.dataSource != null;
  }
  isSearchButtonDisable(): boolean {
    const regex = RegExp(`^([A-Za-z])+`);
    return regex.test(this.searchedValue);
  }
  // END - check Displays
  // Handle see more
  onSeeMore() {
    this.dataSource = new MatTableDataSource(this.handleSizeRowTable(true));
  }
  isMoreToSee(): boolean {
    return !(this.dataSource?.data.length === 0 ||
      this.tmpAllTableArray?.length === this.dataSource?.data.length);
  }
  // END - Handle see more
  onSelectAccount(account: UserRowDataTable) {
    this.router.navigate([`manageAccount`], {
      queryParams: {
        userId: account.userId,
        companyId: account.companyId
      }
    });
  }
  ngOnDestroy(): void {
    this.numberRowToDisplay = 0;
    this.currentIndexStop = 0;
    this.adminService.searchUsersAndCompaniesForDataTable(null);
    this.tmpAllTableArray = [];
    this.dataSource = null;
    this.isDataForTableFounded();
  }
}
