import {Component, OnInit, Input, Output, EventEmitter} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Observable} from "rxjs";
import {startWith, map, debounceTime} from "rxjs/operators";
import {DeliveryAddress} from "@app/shared/_models/deliveryAddress.model";
import {AccountAndCompaniesService} from "@app/shared/_services/accountAndCompanies/account-and-companies.service";
import {RequiredFields} from "@app/shared/_models/companyOptions.model";
import {AutocompleteService} from "@app/shared/autocomplete/autocomplete.service";
import {AddressInfo} from "@models/googleService.model";

@Component({
  selector: "app-delivery-info",
  templateUrl: "./delivery-info.component.html"
})
export class DeliveryInfoComponent implements OnInit {
  @Input() delivery_data: DeliveryAddress[];
  @Output() isValid = new EventEmitter();
  optionsAddress: any;
  selected = false;

  public deliveryInfoForm: FormGroup;
  deliveryAddressSelected: DeliveryAddress;
  filteredAddresses: Observable<DeliveryAddress[]>;
  noDataToAutoComplete: string[] = ["Thank you to complete the form"];
  requiredDeliveryFields: RequiredFields;

  constructor(
    private fb: FormBuilder,
    private accountSer: AccountAndCompaniesService,
    private autocompleteService: AutocompleteService
  ) {
    this.isDataProvided();
  }

  ngOnInit(): void {
    this.initEmptyFormValue();
    this.accountSer.currentOptions$.subscribe(data => {
      if (data?.requiredFields) {
        this.requiredDeliveryFields = data.requiredFields;
        this.setRequiredFields();
      }
    });
  }

  initEmptyFormValue() {
    this.deliveryInfoForm = this.fb.group({
      name: [""],
      phone: [null],
      mail: [null, Validators.email],
      complement: [null],
      address: [null],
      zip: [null],
      city: [null],
      state: [null],
      see: [null],
      note: [null]
    });
    this.deliveryInfoForm.valueChanges.subscribe(() => {
      this.isValid.emit(this.deliveryInfoForm);
    });

    this.deliveryInfoForm.get("address").valueChanges.subscribe((data) => {
      this.getAddress(data);
    });
  }

  addressSelected(option) {
    this.selected = true;
    this.autocompleteService.getAddressInfo(option.place_id).subscribe((res: any) => {
      const address: Partial<AddressInfo> = this.autocompleteService.getAddressFromGoogleObject(res.data.address_components);
      this.deliveryInfoForm.patchValue({
        address: address.street_number === undefined ? "" : address.street_number + " " + address.route,
        zip: address.postal_code,
        city: address.locality,
        state: address.administrative_area_level_1
      });
      this.deliveryInfoForm.markAllAsTouched();
      setTimeout(() => {
        this.selected = false;
      }, 1000);
    });
  }

  getAddress(input: string) {
    if (input.length > 3 && this.selected === false) {
      this.autocompleteService.getAddress(input)
        .pipe(debounceTime(100))
        .subscribe((data) => {
          this.optionsAddress = data;
        });
    }
  }

  setRequiredFields() {
    if (this.requiredDeliveryFields) {
      let formKeys = Object.keys(this.deliveryInfoForm.getRawValue()); //return array of all the keys of the form
      let i = 0;
      let maxIndexDeliveryInfoRequ = 9; //i<9 because of the MDD
      for (const key in this.requiredDeliveryFields) {
        if (this.requiredDeliveryFields[key] && i <= maxIndexDeliveryInfoRequ) {
          this.deliveryInfoForm
            .get(formKeys[i])
            .setValidators(Validators.required);
        }
        i = i + 1;
      }
      this.deliveryInfoForm.updateValueAndValidity();
    }
  }

  displayAutoComplete(): void {
    this.filteredAddresses = this.deliveryInfoForm.valueChanges.pipe(
      startWith(""),
      map(value => this._filterDeliveryByaddressName(value))
    );
  }

  getAddressOptionValue(address: DeliveryAddress): string {
    return `${address.name} - ${address.address}, ${address.zip} ${address.city}`;
  }

  private _filterDeliveryByaddressName(
    inputValues: DeliveryAddress
  ): DeliveryAddress[] {
    if (inputValues) {
      if (this.delivery_data) {
        this.isDataProvided();
        return this.delivery_data.filter(address => {
          const checkName = this._filterName(
            inputValues.name.toLowerCase(),
            address.name.toLowerCase()
          );
          if (checkName) {
            return address;
          }
        });
      }
    }
    return this.delivery_data;
  }

  public isDataProvided(): boolean {
    if (this.delivery_data) {
      return true;
    } else {
      return false;
    }
  }

  private _filterName(inputString: string, storedaddressName: string): boolean {
    if (storedaddressName.indexOf(inputString) === 0) {
      return true;
    } else {
      return false;
    }
  }

  setFormValues(selectedaddress: DeliveryAddress): void {
    this.deliveryInfoForm
      .get("address")
      .setValue(selectedaddress.address || "");
    this.deliveryInfoForm.get("phone").setValue(selectedaddress.phone || "");
    this.deliveryInfoForm.get("city").setValue(selectedaddress.city || "");
    this.deliveryInfoForm.get("mail").setValue(selectedaddress.mail || "");
    this.deliveryInfoForm.get("state").setValue(selectedaddress.state || "");
    this.deliveryInfoForm.get("zip").setValue(selectedaddress.zip || "");
    this.deliveryInfoForm
      .get("complement")
      .setValue(selectedaddress.complement || "");
    this.deliveryInfoForm.get("see").setValue(selectedaddress.see || "");
    this.deliveryInfoForm.get("note").setValue("");
    this.deliveryInfoForm.markAllAsTouched();
    //add patternes and required fields
    this.setRequiredFields();
  }
}
