import { Pipe, PipeTransform } from "@angular/core";
@Pipe({ name: "formatTime" })
export class FormatTimePipe implements PipeTransform {
  transform(inputTime: string) {
    // let hourNumb: any = Number(inputTime.substring(0, 2));
    // hourNumb =
    //   String(hourNumb) == "NaN" ? Number(inputTime.substring(0, 1)) : hourNumb;

    const subString = inputTime.split(":");
    const subMinString = subString[1].split(" ");
    const minNumb = Number(subMinString[0]);
    return (
      subString[0] + //else if no Hour
      ":" +
      this.addZeroToNonDecimal(minNumb) +
      " " +
      inputTime.substr(inputTime.length - 2)
    );
  }
  addZeroToNonDecimal(num: number): string {
    const numStringify = String(num);
    if (numStringify.length == 1) return "0" + numStringify;
    else return numStringify;
  }
}
