import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Component, Inject } from "@angular/core";

export interface EcourierCredentialData {
  login: string;
  password: string;
}
/**
 * @title Ecourier Overview
 */
@Component({
  selector: "ecourier-modal",
  templateUrl: "ecourier-modal.html"
})
export class EcourierModal {
  constructor(
    public ecourierModalRef: MatDialogRef<EcourierModal>,
    @Inject(MAT_DIALOG_DATA) public data: EcourierCredentialData
  ) {}

  // onClick(): void {
  //   this.ecourierModalRef.close();
  // }
}
