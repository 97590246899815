import { Component, OnInit } from "@angular/core";
import { AuthentificationService } from '@app/shared/_services/auth/authentification.service';

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html"
})
export class HeaderComponent implements OnInit {
  constructor(private authentificationService: AuthentificationService) {}

  ngOnInit(): void {}

  logout(){
    this.authentificationService.logout();
  }
}
