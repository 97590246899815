import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { AccountAndCompaniesService } from "@app/shared/_services/accountAndCompanies/account-and-companies.service";
import { ServiceType } from "@app/shared/_models/companyOptions.model";
import { DeliveryTypes } from "@models/deliveryDetails";

enum Window {
  "1 MINUTE" = 1,
  "5 MINUTES" = 5,
  "10 MINUTES" = 10,
  "15 MINUTES" = 15,
  "30 MINUTES" = 30,
  "1 HOUR" = 60,
  "2 HOUR-ALL" = 120,
  "2 HOUR-EVEN" = "EVEN",
  "2 HOUR-ODD" = "ODD"
}

@Component({
  selector: "app-delivery-detail",
  templateUrl: "./delivery-detail.component.html"
})
export class DeliveryDetailComponent implements OnInit {
  @Output() isValid = new EventEmitter();
  @Input() orderOptions: any;
  public deliveryDetailForm: FormGroup;

  public selectedService: number = null;
  public minutesDeliveryWindow: any;
  public selectedDate: Date = new Date();

  public minDate: Date = new Date();
  public isWindowTimeDisplay: boolean = false;
  public optionServices: ServiceType[] = new Array<ServiceType>();

  public DTypes = DeliveryTypes;

  constructor(private fb: FormBuilder) {
    this.deliveryDetailForm = this.fb.group({
      service: [undefined, Validators.required],
      type: [undefined],
      date: [new Date(), Validators.required],
      window: [undefined, Validators.required]
    });
    this.deliveryDetailForm.valueChanges.subscribe(() => {
      this.isValid.emit(this.deliveryDetailForm);
    });
  }

  ngOnInit(): void {
    if (this.orderOptions) {
      this.initServicesObject(this.orderOptions?.serviceTypes);
      this.minutesDeliveryWindow =
        typeof this.orderOptions?.deliveryWindow == "number"
          ? Number(Window[this.orderOptions?.deliveryWindow])
          : Window[this.orderOptions?.deliveryWindow];
    }
  }

  initServicesObject(services: ServiceType[]): void {
    services.forEach(aService => {
      if (aService.enable) this.optionServices.push(aService);
    });
  }
  onTimeWindowFormChange(formTimeValue: any): void {
    this.deliveryDetailForm.get(`window`).setValue(formTimeValue);
  }
  onServiceChange(event: string) {
    const tmp = this.optionServices.filter(service => service.title === event);
    if (tmp) this.selectedService = tmp[0].duration;
    if (this.selectedDate != null) this.isWindowTimeDisplay = true;
  }
  onDateChange(event: MatDatepickerInputEvent<Date>) {
    console.log(event.value);
    this.selectedDate = event.value;
    this.deliveryDetailForm
      .get("date")
      .setValue(this.selectedDate);
    if (this.selectedService != null) this.isWindowTimeDisplay = true;
  }
  stringifyLocalDate(myDate: Date): string {
    const DD = String(myDate.getDate()).padStart(2, "0");
    const MM = String(myDate.getMonth() + 1).padStart(2, "0");
    const YYYY = myDate.getFullYear();
    return MM + "/" + DD + "/" + YYYY;
  }
}
