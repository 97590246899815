<app-header></app-header>
<section>
  <div class="example-form" *ngIf="user">
    <mat-grid-list cols="2" rows="4" rowHeight="100px" [gutterSize]="'20px'">
      <mat-grid-tile class="mat-grid-tile-left">
        <h2>USER ACCOUNT INFO</h2>
      </mat-grid-tile>
      <mat-grid-tile rowspan="4" colspan="1" class="tile-button-modify-user">
        <button
          id="modify-user-button"
          class="button mat-button-inverse"
          mat-raised-button
          (click)="goToModifyUserAccount()"
        >
          MODIFY USER INFO
        </button>
      </mat-grid-tile>
      <mat-grid-tile rowspan="1" colspan="1" class="mat-grid-tile-left">
        <mat-form-field class="input-width">
          <mat-label>User Name</mat-label>
          <input matInput [(ngModel)]="userName" disabled />
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile rowspan="1" colspan="1" class="mat-grid-tile-left">
        <mat-form-field class="input-width">
          <mat-label>User E-Mail</mat-label>
          <input matInput [(ngModel)]="user.email" disabled />
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile rowspan="1" colspan="1" class="mat-grid-tile-left">
        <mat-form-field class="input-width">
          <mat-label>Status</mat-label>
          <mat-select [(value)]="user.status" disabled>
            <mat-option [value]="'1'">Active</mat-option>
            <mat-option [value]="'0'">Disable</mat-option>
          </mat-select>
        </mat-form-field>
      </mat-grid-tile>
    </mat-grid-list>
  </div>
  <div class="background" *ngIf="user">
    <section style="padding-top:20px;">
      <mat-grid-list cols="2" rows="1" rowHeight="100px" [gutterSize]="'20px'">
        <mat-grid-tile colspan="1" class="mat-grid-tile-left">
          <mat-form-field class="input-width">
            <mat-label>Choose Client</mat-label>
            <mat-select [(value)]="currentCompanyId">
              <div *ngFor="let company of companies">
                <mat-option
                  id="input-option-company"
                  ngDefaultControl
                  [value]="company.companyId"
                  (click)="selectedCompany(company)"
                  *ngIf="company.companyName"
                >
                  {{ company.companyName }} - {{ company.customerCode }}
                </mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile colspan="1" class="tile-button-modify-user">
          <button
            class="button mat-button-inverse"
            id="button-modify-client"
            mat-raised-button
            (click)="setmodifyClient()"
            *ngIf="this.currentCompany && !isUpdating"
          >
            MODIFY CLIENT
          </button>
        </mat-grid-tile>
      </mat-grid-list>
      <div class="fields-container pick-up">
        <div *ngIf="currentCompany">
          <form [formGroup]="formClient" class="example-form">
            <h2>PICK UP INFO</h2>
            <div formGroupName="pickupInfo" class="fields-container">
              <mat-form-field class="input-width">
                <mat-label>Pick Up Name*</mat-label>
                <input
                  matInput
                  placeholder="Name"
                  formControlName="name"
                  id="input-pickup-name"
                />
              </mat-form-field>
              <mat-form-field class="input-width">
                <mat-label>Pick Up Phone*</mat-label>
                <input
                  matInput
                  placeholder="Pick up phone"
                  formControlName="phone"
                  id="input-pickup-phone"
                />
              </mat-form-field>
              <mat-form-field class="input-width">
                <mat-label>Pick Up Address*</mat-label>
                <input
                  matInput
                  placeholder="Pick up address"
                  formControlName="address"
                  id="input-pickup-address"
                  [matAutocomplete]="auto1"
                  autocomplete="false"
                />
                <mat-autocomplete #auto1="matAutocomplete">
                  <mat-option *ngFor="let option of optionsAddress" [value]="option.description" (onSelectionChange)="addressSelected(option)">{{option.description}}</mat-option>
                </mat-autocomplete>
              </mat-form-field>
              <mat-form-field class="input-width">
                <mat-label>Pick Up City*</mat-label>
                <input
                  matInput
                  placeholder="Pick up city"
                  formControlName="city"
                  id="input-pickup-city"
                />
              </mat-form-field>
              <mat-form-field class="input-width">
                <mat-label>Pick Up State*</mat-label>
                <input
                  matInput
                  placeholder="Pick up state"
                  formControlName="state"
                  id="input-pickup-state"
                />
              </mat-form-field>
              <mat-form-field class="input-width">
                <mat-label>Pick Up ZipCode*</mat-label>
                <input
                  matInput
                  placeholder="Pick up zipCode"
                  formControlName="zip"
                  id="input-pickup-zip"
                />
              </mat-form-field>
              <mat-form-field class="input-width">
                <mat-label>Pick Up Apt / Room / Floor / Suite#</mat-label>
                <input
                  matInput
                  placeholder="Pick up App,Room,Floor,Suite"
                  formControlName="complement"
                  id="input-pickup-complement"
                />
              </mat-form-field>
              <mat-form-field class="input-width">
                <mat-label>Pick Up see (on-site Contact)</mat-label>
                <input
                  matInput
                  placeholder="Pick up see"
                  formControlName="see"
                  id="input-pickup-see"
                />
              </mat-form-field>
            </div>
            <div formArrayName="serviceTypes">
              <table
                class="mat-table"
                style="background-color: #fafafa;margin-bottom: 100px;"
              >
                <tr class="mat-header-row">
                  <th class="mat-header-cell">SERVICE TYPE</th>
                  <th class="mat-header-cell">YES / NO</th>
                  <th class="mat-header-cell">LABEL</th>
                  <th class="mat-header-cell">DURATION</th>
                </tr>

                <tr
                  class="mat-row"
                  *ngFor="
                    let serviceType of serviceTypesArray.controls;
                    let serviceTypeIndex = index
                  "
                  [formGroup]="serviceTypesArray.controls[serviceTypeIndex]"
                >
                  <td
                    class="mat-cell"
                    id="input-service-type-title-{{ serviceTypeIndex }}"
                  >
                    {{ serviceType.get("title").value }}
                  </td>

                  <td class="mat-cell">
                    <mat-radio-group
                      formControlName="enable"
                      [disabled]="!isUpdating"
                      id="input-service-type-enable-{{ serviceTypeIndex }}"
                    >
                      <mat-radio-button [value]="true">YES</mat-radio-button>
                      <mat-radio-button [value]="false">NO</mat-radio-button>
                    </mat-radio-group>
                  </td>
                  <td class="mat-cell">
                    <div class="table-cell-label-wrapper">
                      <input
                        matInput
                        type="text"
                        [disabled]="!isUpdating"
                        formControlName="label"
                        id="input-service-type-label-{{ serviceTypeIndex }}"
                      />
                    </div>
                  </td>
                  <td
                    class="mat-cell"
                    id="input-service-type-duration-{{ serviceTypeIndex }}"
                  >
                    {{ serviceType.get("duration").value }}'
                  </td>
                </tr>
              </table>
            </div>
            <h2>DELIVERY WINDOW STARTS EVERY</h2>
            <mat-radio-group
              class="radio-group-delivery-window"
              layout="row"
              formControlName="deliveryWindow"
            >
              <mat-radio-button
                labelPosition="after"
                class="example-radio-button"
                *ngFor="let windowTime of windowTimes; let i = index"
                [value]="windowTime"
                id="input-window-time-{{ i }}"
              >
                {{ windowTime }}
              </mat-radio-button>
            </mat-radio-group>
            <div formGroupName="requiredFields" style="margin-top: 50px;">
              <table
                class="mat-table"
                style="background-color: #fafafa;margin-bottom: 100px;"
              >
                <tr class="mat-header-row">
                  <th class="mat-header-cell">FIELDS</th>
                  <th class="mat-header-cell">MANDATORY / OPTIONAL / INVISIBLE</th>
                </tr>
                <tr class="mat-row">
                  <td class="mat-cell">DELIVERY NAME</td>
                  <td class="mat-cell">
                      <mat-select formControlName="nameDelivery" >
                        <mat-option [value]="true">MANDATORY</mat-option>
                        <mat-option [value]="false">OPTIONAL</mat-option>
                        <mat-option [value]="0">INVISIBLE</mat-option>
                      </mat-select>
                  </td>
                </tr>
                <tr class="mat-row">
                  <td class="mat-cell">DELIVERY PHONE</td>
                  <td class="mat-cell">
                    <mat-select formControlName="phoneDelivery" class="selectMOI">
                      <mat-option [value]="true">MANDATORY</mat-option>
                      <mat-option [value]="false">OPTIONAL</mat-option>
                      <mat-option [value]="0">INVISIBLE</mat-option>
                    </mat-select>
                  </td>
                </tr>
                <tr class="mat-row">
                  <td class="mat-cell">DELIVERY EMAIL</td>
                  <td class="mat-cell">
                    <mat-select formControlName="emailDelivery" class="selectMOI">
                      <mat-option [value]="true">MANDATORY</mat-option>
                      <mat-option [value]="false">OPTIONAL</mat-option>
                      <mat-option [value]="0">INVISIBLE</mat-option>
                    </mat-select>
                  </td>
                </tr>
                <tr class="mat-row">
                  <td class="mat-cell">DELIVERY ADDRESS</td>
                  <td class="mat-cell">
                    <mat-select formControlName="addressDelivery" class="selectMOI" [disabled]="true">
                      <mat-option [value]="true">MANDATORY</mat-option>
                      <mat-option [value]="false">OPTIONAL</mat-option>
                      <mat-option [value]="0">INVISIBLE</mat-option>
                    </mat-select>
                  </td>
                </tr>
                <tr class="mat-row">
                  <td class="mat-cell">DELIVERY ZIP CODE</td>
                  <td class="mat-cell">
                    <mat-select formControlName="zipDelivery" class="selectMOI" [disabled]="true">
                      <mat-option [value]="true">MANDATORY</mat-option>
                      <mat-option [value]="false">OPTIONAL</mat-option>
                      <mat-option [value]="0">INVISIBLE</mat-option>
                    </mat-select>
                  </td>
                </tr>
                <tr class="mat-row">
                  <td class="mat-cell">DELIVERY CITY</td>
                  <td class="mat-cell">
                    <mat-select formControlName="cityDelivery" class="selectMOI" [disabled]="true">
                      <mat-option [value]="true">MANDATORY</mat-option>
                      <mat-option [value]="false">OPTIONAL</mat-option>
                      <mat-option [value]="0">INVISIBLE</mat-option>
                    </mat-select>
                  </td>
                </tr>
                <tr class="mat-row">
                  <td class="mat-cell">DELIVERY STATE</td>
                  <td class="mat-cell">
                    <mat-select formControlName="stateDelivery" class="selectMOI" [disabled]="true">
                      <mat-option [value]="true">MANDATORY</mat-option>
                      <mat-option [value]="false">OPTIONAL</mat-option>
                      <mat-option [value]="0">INVISIBLE</mat-option>
                    </mat-select>
                  </td>
                </tr>
                <tr class="mat-row">
                  <td class="mat-cell">DELIVERY APT / ROOM / FLOOR / SUITE#</td>
                  <td class="mat-cell">
                    <mat-select formControlName="complementDelivery" class="selectMOI">
                      <mat-option [value]="true">MANDATORY</mat-option>
                      <mat-option [value]="false">OPTIONAL</mat-option>
                      <mat-option [value]="0">INVISIBLE</mat-option>
                    </mat-select>
                  </td>
                </tr>
                <tr class="mat-row">
                  <td class="mat-cell">DELIVERY SEE</td>
                  <td class="mat-cell">
                    <mat-select formControlName="seeDelivery" class="selectMOI">
                      <mat-option [value]="true">MANDATORY</mat-option>
                      <mat-option [value]="false">OPTIONAL</mat-option>
                      <mat-option [value]="0">INVISIBLE</mat-option>
                    </mat-select>
                  </td>
                </tr>
                <tr class="mat-row">
                  <td class="mat-cell">DELIVERY NOTE</td>
                  <td class="mat-cell">
                    <mat-select formControlName="noteDelivery" class="selectMOI">
                      <mat-option [value]="true">MANDATORY</mat-option>
                      <mat-option [value]="false">OPTIONAL</mat-option>
                      <mat-option [value]="0">INVISIBLE</mat-option>
                    </mat-select>
                  </td>
                </tr>
                <tr class="mat-row">
                  <td class="mat-cell">AMOUNT OF PIECES</td>
                  <td class="mat-cell">
                    <mat-select formControlName="pieces" class="selectMOI">
                      <mat-option [value]="true">MANDATORY</mat-option>
                      <mat-option [value]="false">OPTIONAL</mat-option>
                      <mat-option [value]="0">INVISIBLE</mat-option>
                    </mat-select>
                  </td>
                </tr>
                <tr class="mat-row">
                  <td class="mat-cell">WEIGHT</td>
                  <td class="mat-cell">
                    <mat-select formControlName="weight" class="selectMOI">
                      <mat-option [value]="true">MANDATORY</mat-option>
                      <mat-option [value]="false">OPTIONAL</mat-option>
                      <mat-option [value]="0">INVISIBLE</mat-option>
                    </mat-select>
                  </td>
                </tr>
                <tr class="mat-row">
                  <td class="mat-cell">ORDER DESCRIPTION</td>
                  <td class="mat-cell">
                    <mat-select formControlName="orderDescription" class="selectMOI">
                      <mat-option [value]="true">MANDATORY</mat-option>
                      <mat-option [value]="false">OPTIONAL</mat-option>
                      <mat-option [value]="0">INVISIBLE</mat-option>
                    </mat-select>
                  </td>
                </tr>
              </table>
            </div>
          </form>
        </div>
      </div>
      <div *ngIf="isUpdating">
        <button
          style="margin-right:20px"
          class="button"
          mat-raised-button
          id="button-submit-client"
          color="primary"
          (click)="submitClientForm()"
          [disabled]="!formClient.valid"
          [class.disable-button]="!formClient.valid"
        >
          SAVE CHANGES
        </button>
        <button
          id="button-cancel"
          class="button mat-button-inverse"
          mat-raised-button
          (click)="cancel()"
        >
          CANCEL
        </button>
      </div>
    </section>
  </div>
</section>
