import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import {MatExpansionModule} from "@angular/material/expansion";

import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatButtonModule } from "@angular/material/button";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatInputModule } from "@angular/material/input";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";

import { LoginPageComponent } from "./login-page/login-page.component";
import { PickUpFormComponent } from "./forms/_newOrder/pick-up-form/pick-up-form.component";
import { DeliveryInfoComponent } from "./forms/_newOrder/delivery-info/delivery-info.component";
import { DeliveryDetailComponent } from "./forms/_newOrder/delivery-detail/delivery-detail.component";
import { OrderDetailComponent } from "./forms/_newOrder/order-detail/order-detail.component";
import { TimeWindowComponent } from "./forms/_newOrder/delivery-detail/time-window/time-window/time-window.component";
import { RequiredInputStarPipe } from "./pipes/requiredInputStarPipe";
import { FormatTimePipe } from "./pipes/formatTimePipe";


const MaterialComponents = [
  MatButtonModule,
  MatGridListModule,
  MatFormFieldModule,
  MatInputModule,
  MatSelectModule,
  MatAutocompleteModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatExpansionModule
];

@NgModule({
  declarations: [
    LoginPageComponent,
    PickUpFormComponent,
    DeliveryInfoComponent,
    DeliveryDetailComponent,
    OrderDetailComponent,
    TimeWindowComponent,
    RequiredInputStarPipe,
    FormatTimePipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    BrowserAnimationsModule,
    MaterialComponents,
    ReactiveFormsModule,
    NgxMaterialTimepickerModule
  ],
  exports: [
    PickUpFormComponent,
    DeliveryInfoComponent,
    DeliveryDetailComponent,
    OrderDetailComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class FeaturesModule {}
