import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject, pipe } from "rxjs";
import { environment } from "@env/environment";
import { HttpClient } from "@angular/common/http";
import { map, tap } from "rxjs/operators";
import { Company } from "@app/shared/_models/company.model";
import { UserDataPost } from "../_models/userDataToPost";
import { _respRequest } from "@app/shared/_models/_respRequest.model";
import { UserRowDataTable } from "../_models/user-row-data-table";

@Injectable({
  providedIn: "root"
})
export class AdminService {
  private dataTable$: BehaviorSubject<Array<any>> = new BehaviorSubject<
    Array<any>
  >(null);

  //Handle url with basePath + edit in each request
  protected basePath = environment.apiUrl;
  protected readonly basePathConst = this.basePath;
  protected readonly basePathUser = `${this.basePathConst}/secure/users/`;

  constructor(private http: HttpClient) {}

  // search for feel dataTable by user or/and companies
  get dataTable() {
    return this.dataTable$.asObservable();
  }
  public searchUsersAndCompaniesForDataTable(criteria: string) {
    this.basePath = this.basePathUser;
    if (criteria == null) this.dataTable$.next(null);
    else {
      this.http
        .get<_respRequest>(this.basePath + "search?search=" + criteria)
        .pipe(
          map(rep => {
            //when 204
            if (rep?.data.length == 0) return new Array(null);
            //good Case
            else {
              const resultsA: any[] = rep?.data;
              return resultsA.map((userRow: UserRowDataTable) =>
                UserRowDataTable.fromJson(userRow)
              );
            }
          })
        )
        .subscribe(
          data => {
            this.dataTable$.next(data);
          },
          err => console.error(err)
        );
    }
  }

  // Handle NEW USER && EDIT USER
  public postNewUser(userData: any, companies: any[]): Observable<any> {
    this.basePath = this.basePathConst;
    const body = this.createBodyToPost(userData, companies);
    return this.http.post<any>(this.basePath + "/secure/users", body).pipe(
      tap(data => {
        return data;
      })
    );
  }
  public putUserById(
    userId: string,
    userData: any,
    companies: any[]
  ): Observable<any> {
    this.basePath = this.basePathUser;
    const body = this.createBodyToPost(userData, companies);
    return this.http.put<any>(this.basePath + userId, body);
  }
  //Create formated Object body  for NEW/Edit USER
  private createBodyToPost(userData: UserDataPost, companies): any {
    let body: any = {
      email: userData.email,
      password: userData.password,
      status: userData.status,
      phone: userData.phone,
      lastName: userData.lastName,
      firstName: userData.firstName,
      isAdmin: false,
      companies: []
    };
    companies.forEach(company => {
      body.companies.push(company[0]);
    });
    return body;
  }

  //OTHERS:
  public getClientsFromAccountId(userId: string): Observable<any> {
    this.basePath = this.basePathUser;
    return this.http.get<any>(this.basePath + userId + "/companies").pipe(
      map(rep => {
        // api = data{}, code, status
        const Acompanies: any[] = rep.data;
        return Acompanies.map((company: Company) => Company.fromJson(company));
      })
    );
  }
}
