<div class="form" *ngIf="this.requiredFields?.weight !== 0 || this.requiredFields?.pieces !== 0 || this.requiredFields?.orderDescription !== 0">
  <div class="horizontal-split"></div>
  <h3 class="form-title">ORDER DETAILS</h3>
  <form [formGroup]="orderDetailForm" autocomplete="off">
    <mat-form-field class="input-width"  *ngIf="this.requiredFields?.pieces !== 0">
      <mat-label
        >Amount of Pieces{{
          orderDetailForm | starRequiredInput: "pieces"
        }}</mat-label
      >
      <input
        matInput
        type="number"
        min="0"
        id="input-order-pieces"
        formControlName="pieces"
      />
    </mat-form-field>
    <mat-form-field class="input-width" *ngIf="this.requiredFields?.weight !== 0">
      <mat-label
        >Total Weight (lbs){{ orderDetailForm | starRequiredInput: "weight" }}
      </mat-label>
      <input
        matInput
        type="number"
        min="0"
        id="input-order-weight"
        formControlName="weight"
        value=""
      />
    </mat-form-field>
    <mat-form-field class="input-note" *ngIf="this.requiredFields?.orderDescription !== 0">
      <mat-label>Order Description</mat-label>
      <textarea
        matInput
        type="textarea"
        id="input-order-note"
        formControlName="note"
        value=""
      >
      </textarea>
    </mat-form-field>
  </form>
</div>
