<h3 class="center-align title" style="font-size: 20px; color: #1e2855;">
  ORDER ID : <b>{{ orderNumber }}</b>
</h3>
<div class="trackOrder-form">
  <div class="background main-block">
    <section class="track-section-margin">
      <div class="pickUpForm">
        <h3 class="form-title">PICK UP INFO</h3>
        <form [formGroup]="orderForm">
          <!-- PICKUP FORM -->
          <div formGroupName="pickUp" class="fields-container">
            <!-- TODO: formControlName="name"  -->
            <mat-form-field class="input-width">
              <mat-label>Pick Up Name</mat-label>
              <input matInput formControlName="name" id="input-pickup-name" />
            </mat-form-field>
            <mat-form-field class="input-width">
              <mat-label>Pick Up Phone</mat-label>
              <input matInput formControlName="phone" id="input-pickup-phone" />
            </mat-form-field>
            <mat-form-field class="input-width">
              <mat-label>Pick Up Address</mat-label>
              <input matInput formControlName="address" />
            </mat-form-field>
            <mat-form-field class="input-width">
              <mat-label>Pick Up City</mat-label>
              <input matInput formControlName="city" id="input-pickup-city" />
            </mat-form-field>
            <mat-form-field class="input-width">
              <mat-label>Pick Up State</mat-label>
              <input matInput formControlName="state" id="input-pickup-state" />
            </mat-form-field>
            <mat-form-field class="input-width">
              <mat-label>Pick Up ZipCode</mat-label>
              <input matInput formControlName="zip" id="input-pickup-zip" />
            </mat-form-field>
            <mat-form-field class="input-width">
              <mat-label>Pick Up Apt / Room / Floor / Suite#</mat-label>
              <input
                matInput
                formControlName="complement"
                id="input-pickup-complement"
              />
            </mat-form-field>
            <mat-form-field class="input-width">
              <mat-label>Pick Up see (on-site Contact)</mat-label>
              <input matInput formControlName="see" id="input-pickup-see" />
            </mat-form-field>
            <mat-form-field class="input-width">
              <mat-label>Pick Up Note</mat-label>
              <input matInput formControlName="note" id="input-pickup-note" />
            </mat-form-field>
          </div>
        </form>
      </div>
      <div class="deliveryInfoForm">
        <h3 class="form-title">DELIVERY INFO</h3>
        <form [formGroup]="orderForm">
          <!-- DELIVERY INFO FORM -->
          <div formGroupName="deliveryInfo" class="fields-container">
            <mat-form-field class="input-width">
              <mat-label>Delivery Name</mat-label>
              <input matInput formControlName="name" />
            </mat-form-field>
            <mat-form-field class="input-width">
              <mat-label>Delivery Phone</mat-label>
              <input matInput formControlName="phone" />
            </mat-form-field>
            <mat-form-field class="input-width">
              <mat-label>Delivery Address</mat-label>
              <input matInput formControlName="address" />
            </mat-form-field>
            <mat-form-field class="input-width">
              <mat-label>Delivery City</mat-label>
              <input matInput formControlName="city" />
            </mat-form-field>
            <mat-form-field class="input-width">
              <mat-label>Delivery State</mat-label>
              <input matInput formControlName="state" />
            </mat-form-field>
            <mat-form-field class="input-width">
              <mat-label>Delivery Zip Code</mat-label>
              <input matInput formControlName="zip" />
            </mat-form-field>
            <mat-form-field class="input-width">
              <mat-label>Delivery Apt / Room / Floor / Suite#</mat-label>
              <input matInput formControlName="complement" />
            </mat-form-field>
            <mat-form-field class="input-width">
              <mat-label>Delivery see (on-site Contact)</mat-label>
              <input matInput formControlName="see" />
            </mat-form-field>
            <mat-form-field class="input-width">
              <mat-label>Delivery Email</mat-label>
              <input matInput formControlName="mail" />
            </mat-form-field>
            <mat-form-field class="input-width">
              <mat-label>Delivery Note</mat-label>
              <input matInput formControlName="note" />
            </mat-form-field>
          </div>
        </form>
      </div>
      <div class="deliveryDetailsForm">
        <h3 class="form-title">DELIVERY DETAILS</h3>
        <form [formGroup]="orderForm">
          <!-- DELIVERY DETAILS FORM -->
          <div formGroupName="deliveryDetails" class="fields-container">
            <mat-form-field class="input-width">
              <mat-label>Service Type</mat-label>
              <input matInput formControlName="service" />
            </mat-form-field>
            <mat-form-field class="input-width">
              <mat-label>Delivery Date</mat-label>
              <input matInput [value]="deliveryDetails.OrderDate" disabled />
            </mat-form-field>
            <mat-form-field class="input-width">
              <mat-label>Delivery Type</mat-label>
              <input matInput formControlName="deliveryType" />
            </mat-form-field>
            <!-- DELIVERY WINDOW -->
            <mat-form-field class="delivery-window">
              <mat-label>Delivery Window</mat-label>
              <div>
                <p style=" margin-right: 15%;">
                  FROM :&nbsp;&nbsp;&nbsp;
                  {{ deliveryDetails.timeFrom | date: "shortTime" }}
                </p>
                <p>
                  TO :&nbsp;&nbsp;&nbsp;
                  {{ deliveryDetails.timeTo | date: "shortTime" }}
                </p>
                <!-- KEEP style hack -->
                <input
                  style="visibility: hidden;"
                  matInput
                  formControlName="deliveryType"
                />
              </div>
            </mat-form-field>
          </div>
        </form>
      </div>
      <div class="orderInfoForm" *ngIf="this.orderInfo.pieces !== '0' || this.orderInfo.weight != '0' || this.orderInfo.note != ''">
        <h3 class="form-title">ORDER INFO</h3>
        <form [formGroup]="orderForm">
          <!-- ORDER INFO FORM -->
          <div formGroupName="orderInfo" class="fields-container">
            <mat-form-field class="input-width" *ngIf="this.orderInfo.pieces !== '0'">
              <mat-label>Amount of Pieces</mat-label>
              <input matInput formControlName="pieces" />
            </mat-form-field>
            <mat-form-field class="input-width" *ngIf="this.orderInfo.weight != '0'">
              <mat-label>Total Weight (lbs)</mat-label>
              <input matInput formControlName="weight" />
            </mat-form-field>
            <mat-form-field class="input-note" *ngIf="this.orderInfo.note != ''">
              <mat-label>Order Description</mat-label>
              <input matInput formControlName="note" id="input-order-note" />
            </mat-form-field>
          </div>
        </form>
      </div>
    </section>
  </div>
  <div class="background secondary-block">
    <section class="track-section-margin">
      <div class="orderDetailsForm">
        <h3 class="form-title">ORDER DETAILS</h3>
        <form [formGroup]="orderForm">
          <div formGroupName="orderDetails" class="fields-container">
            <mat-form-field class="input-width">
              <mat-label>Order Created</mat-label>
              <input matInput formControlName="Created" />
            </mat-form-field>
            <mat-form-field class="input-width">
              <mat-label>Order Dispatched</mat-label>
              <input matInput formControlName="Dispatch" />
            </mat-form-field>
            <mat-form-field class="input-width">
              <mat-label>Order Picked Up</mat-label>
              <input matInput formControlName="PickUp" />
            </mat-form-field>
            <mat-form-field class="input-width">
              <mat-label>Pick Up POD Name</mat-label>
              <input matInput formControlName="podName" />
            </mat-form-field>
            <mat-form-field class="input-width">
              <mat-label>Order Delivered</mat-label>
              <input matInput formControlName="Delivered" />
            </mat-form-field>
            <mat-form-field class="input-width">
              <mat-label>Delivery POD Name</mat-label>
              <input matInput formControlName="podSign" />
            </mat-form-field>
          </div>
        </form>
      </div>
    </section>
  </div>
</div>
