<div class="container-login">
  <div class="img-login" flex></div>
  <div flex class="login-section">
    <div class="login-section-container">
      <div class="login-title">
        Sign in to Dutch Express
      </div>
      <div>
        <form class="example-form" (ngSubmit)="onSubmit()">
          <mat-form-field class="example-full-width">
            <mat-label>Email</mat-label>
            <input
              matInput
              id="input-mail"
              [formControl]="emailFormControl"
              [errorStateMatcher]="matcher"
              placeholder="Enter your email*"
            />
            <mat-error
              *ngIf="
                emailFormControl.hasError('email') &&
                !emailFormControl.hasError('required')
              "
            >
              Please enter a valid email address
            </mat-error>
            <mat-error *ngIf="emailFormControl.hasError('required')">
              Email is <strong>required</strong>
            </mat-error>
          </mat-form-field>
          <br />
          <mat-form-field class="example-full-width">
            <mat-label>Password</mat-label>
            <input
              matInput
              id="input-password"
              [formControl]="password"
              [type]="hide ? 'password' : 'text'"
              placeholder="Enter your password"
            />
            <mat-error *ngIf="password.hasError('required')"
              >Password is required</mat-error
            >
          </mat-form-field>
          <br />
          <button
            class="signin-button"
            mat-raised-button
            id="input-button-login"
            type="submit"
            [disabled]="!emailFormControl.valid"
            color="primary"
          >
            SIGN IN
          </button>
          <p style="color: red;">{{ responseLogin }}</p>
        </form>
      </div>

    </div>
  </div>
</div>
