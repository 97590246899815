import {Component, ViewChild} from '@angular/core';
import {AccountAndCompaniesService} from '@app/shared/_services/accountAndCompanies/account-and-companies.service';
import {Company} from '@app/shared/_models/company.model';

import {Subscription, Observable, BehaviorSubject} from 'rxjs';

import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';

import {EcourierModal} from './ecourier-modal/ecourier-modal';
import {BrowserStorageService} from '@app/shared/_services/auth/browser-storage.service';

@Component({
  selector: 'app-new-order-page',
  templateUrl: './new-order-page.component.html'
})
export class NewOrderPageComponent {
  companies: Company[];
  companiesSub: Subscription;
  currentCompany: Company = null;
  selectingCompany: Company;

  constructor(
    private accountCompService: AccountAndCompaniesService,
    private storageService: BrowserStorageService,
  ) {
    this.accountCompService.getCompaniesFromAccountId(
      this.storageService.get('id')
    );
    this.companiesSub = this.accountCompService.Companies.subscribe(
      companies => {
        this.companies = companies;
      },
      err => console.error(err)
    );
  }

  selectCompany(company: Company) {
    this.selectingCompany = company;
    this.currentCompany = this.selectingCompany;
    this.storageService.set('companyId', this.currentCompany.companyId);
    this.getDeliveriesData(this.currentCompany.companyId);
    this.accountCompService.getCompanieOptionsByCompanyId(this.currentCompany.companyId);
  }

  getDeliveriesData(id: string) {
    this.accountCompService.onGetDeliveriesAddresses(id);
  }

  ngOnDestroy(): void {
    if (this.companiesSub) {
      this.companiesSub.unsubscribe();
    }
  }
}
