import { Component, ViewChild, OnDestroy } from "@angular/core";
import { AccountAndCompaniesService } from "@services/accountAndCompanies/account-and-companies.service";
import { Company } from "@app/shared/_models/company.model";
import { Subscription } from "rxjs";
import { BrowserStorageService } from "@app/shared/_services/auth/browser-storage.service";
import { SearchTableOrdersComponent } from "./search-table-orders/search-table-orders.component";

@Component({
  selector: "app-tracking-order-page",
  templateUrl: "./tracking-order-page.component.html"
})
export class TrackingOrderPageComponent implements OnDestroy {
  companiesSub: Subscription;
  companies: Company[];
  selectedCompany: Company;
  isCurrentCompanyDisplay: boolean = false;

  @ViewChild(SearchTableOrdersComponent)
  childRef: SearchTableOrdersComponent;

  constructor(private accountCompService: AccountAndCompaniesService) {
    this.companiesSub = this.accountCompService.Companies.subscribe(
      companies => {
        this.companies = companies;
        this.selectedCompany = null;
      },
      err => console.error(err)
    );
  }
  onSelectedCompany(company: Company) {
    this.onChangeCompany(company);
    this.selectedCompany = company;
    //TOOD : check pour le form -->
    // this.storageService.set("companyId", company.companyId);
  }
  //use to reset the company if a selected company change in the process
  onChangeCompany(company: Company): void {
    //initialization case
    if (this.selectedCompany == null) {
      this.isCurrentCompanyDisplay = true;
    } else {
      if (this.selectedCompany?.companyId != company.companyId)
        this.childRef.setCurrentCompany(company);
    }
  }
  destroyChildrenComponent() {
    this.childRef.ngOnDestroy();
  }
  ngOnDestroy() {
    this.companiesSub.unsubscribe();
  }
}
