<!--suppress ALL -->
<app-header></app-header>
<section>
  <mat-grid-list cols="12" rowHeight="100px">
    <mat-grid-tile class="mat-grid-tile-center" colspan="12" rowspan="2">
      <button
        class="button mat-button-inverse"
        mat-raised-button
        id="input-button-new-user"
        routerLink="/createAccount"
      >
        Create new user
      </button>
    </mat-grid-tile>
    <form>
      <mat-grid-tile class="mat-grid-tile-center margin-input" colspan="6">
        <mat-form-field>
          <mat-label>Search a company name</mat-label>
          <input
            matInput
            pattern=""
            id="input-search-company"
            [(ngModel)]="searchedValue"
            [ngModelOptions]="{ standalone: true }"
          />
          <button mat-button matSuffix mat-icon-button>
            <mat-icon>search</mat-icon>
          </button>
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile class="mat-grid-tile-center" colspan="6">
        <button
          mat-raised-button
          color="primary"
          class="button"
          [disabled]="!isSearchButtonDisable()"
          id="input-button-search"
          (click)="onSearch()"
        >
          Search{{ obs$ | async }}
        </button>
      </mat-grid-tile>
    </form>
    <mat-grid-tile
      grid-tile
      class="mat-grid-tile-left"
      *ngIf="isDataForTableFounded()"
      colspan="4"
      rowspan="1"
    >
    </mat-grid-tile>
  </mat-grid-list>
  <div *ngIf="isDataForTableFounded()" id="results">
    <div><p>RESULTS</p></div>
    <table
      id="companies-search-result-table"
      mat-table
      [dataSource]="dataSource"
      style="width: 100%;"
    >
      <!-- CLIENT NAME Column -->
      <ng-container matColumnDef="companyName">
        <th mat-header-cell *matHeaderCellDef>CLIENT NAME</th>
        <td mat-cell class="mat-cell-user-list" *matCellDef="let account">{{ account.companyName }}</td>
      </ng-container>

      <!-- STATUS Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>STATUS</th>
        <td mat-cell class="mat-cell-user-list" *matCellDef="let account">{{ account.status }}</td>
      </ng-container>

      <!-- DATE CREATED Column -->
      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef>DATE CREATED</th>
        <td mat-cell class="mat-cell-user-list" *matCellDef="let account">{{ account.createdAt }}</td>
      </ng-container>

      <!-- ATTRIBUTEDTO Column -->
      <ng-container matColumnDef="userName">
        <th mat-header-cell *matHeaderCellDef>ATTRIBUTED TO</th>
        <td mat-cell class="mat-cell-user-list" *matCellDef="let account">{{ account.userName }}</td>
      </ng-container>
      <!-- LOADING Column -->
      <tr
        mat-footer-row
        *matFooterRowDef="['loading']"
        [ngClass]="{ hide: dataSource != null }"
      ></tr>
      <ng-container matColumnDef="loading">
        <td colspan="5" mat-footer-cell *matFooterCellDef>Data Loading...</td>
      </ng-container>
      <!-- No Data Column -->
      <tr
        mat-footer-row
        *matFooterRowDef="['noData']"
        [ngClass]="{
          hide: !(dataSource != null && dataSource.data.length == 0)
        }"
      ></tr>
      <ng-container matColumnDef="noData">
        <tr mat-footer-cell *matFooterCellDef style="color:#f05a38;">
          No Data Found!
        </tr>
      </ng-container>
      <!-- DISPLAY HEADER -->
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <!-- FOR ALL ROW -->
      <tr
        mat-row
        id="companies-search-result-row"
        *matRowDef="let row; columns: displayedColumns"
        (click)="onSelectAccount(row)"
      ></tr>
    </table>

    <!-- ERRO CHECK ngIf -->
    <div *ngIf="!isDataForTableFounded()">
      <p style="color: red;">No data provided</p>
    </div>
    <!-- SEE MORE -->
    <div class="see-more">
      <button
        mat-raised-button
        class="button mat-button-inverse "
        color="primary"
        id="button-see-more"
        *ngIf="isMoreToSee()"
        (click)="onSeeMore()"
      >
        SEE MORE
      </button>
    </div>
  </div>
</section>
