import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges
} from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { BrowserStorageService } from "@app/shared/_services/auth/browser-storage.service";
import { PickupInfoOptions } from "@app/shared/_models/companyOptions.model";

@Component({
  selector: "app-pick-up-form",
  templateUrl: "./pick-up-form.component.html"
})
export class PickUpFormComponent implements OnInit {
  @Input() pickUpData: PickupInfoOptions;
  @Output() isValid = new EventEmitter();

  pickUpForm: FormGroup;
  pickUpAddress: any;
  panelOpenState: boolean = false;

  constructor(
    private fb: FormBuilder,
    private storageService: BrowserStorageService
  ) {
    this.initEmptyForm();
    this.ngOnInit();
  }
  getStorageIdComp(): string {
    return this.storageService.get("companyId");
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.pickUpData?.previousValue) {
      if (changes.pickUpData.previousValue != changes.pickUpData.currentValue)
        this.ngOnInit();
    }
  }
  ngOnInit(): void {
    if (this.pickUpData) {
      this.pickUpForm.get("name").disable();
      this.initFormValues();
    }
  }
  initEmptyForm() {
    this.pickUpForm = this.fb.group({
      name: [null, Validators.required],
      address: [null, Validators.required],
      phone: [""],
      state: [null, Validators.required],
      zip: [null, Validators.required],
      city: [null, Validators.required],
      complement: [null],
      see: [null],
      note: [null],
      companyId: [
        {
          value: this.getStorageIdComp(),
          disabled: true
        }
      ]
    });
    this.pickUpForm.valueChanges.subscribe(() => {
      this.pickUpAddress = this.pickUpForm.getRawValue();
      this.isValid.emit(this.pickUpForm);
    });
    this.pickUpForm.updateValueAndValidity();
  }
  initFormValues(): void {
    this.pickUpForm.get("name").setValue(this.pickUpData.name || "");
    this.pickUpForm.get("address").setValue(this.pickUpData.address || "");
    this.pickUpForm.get("state").setValue(this.pickUpData.state || "");
    this.pickUpForm
      .get("complement")
      .setValue(this.pickUpData.complement || "");
    this.pickUpForm.get("phone").setValue(this.pickUpData.phone || "");
    this.pickUpForm.get("zip").setValue(this.pickUpData.zip || "");
    this.pickUpForm.get("city").setValue(this.pickUpData.city || "");
    this.pickUpForm.get("see").setValue(this.pickUpData.see || "");
    this.pickUpForm.get("note").setValue("");
    this.pickUpForm.get("companyId").setValue(this.getStorageIdComp());
    this.pickUpForm.updateValueAndValidity();
  }
}
