import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject, of } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from "@env/environment";
import { BrowserStorageService } from "../auth/browser-storage.service";
import { Company } from "@app/shared/_models/company.model";
import { AddresPickUp } from "@app/shared/_models/addresPickUp.model";
import { map, tap } from "rxjs/operators";
import { _respRequest } from "@app/shared/_models/_respRequest.model";
import { DeliveryAddress } from "@app/shared/_models/deliveryAddress.model";
import { UserAccount } from "@app/shared/_models/userAccount.model";
import { AuthentificationService } from "../auth/authentification.service";
import { OrderFormData } from "@app/shared/_models/orderFormData";
import { CompanyOptions } from "@app/shared/_models/companyOptions.model";

@Injectable({
  providedIn: "root"
})
export class AccountAndCompaniesService {
  private _companies: BehaviorSubject<Company[]> = new BehaviorSubject<
    Company[]
  >(null);

  private _currentAdress: BehaviorSubject<AddresPickUp[]> = new BehaviorSubject<
    AddresPickUp[]
  >(null);
  public currentAdress$ = this._currentAdress as Observable<AddresPickUp[]>;

  private _currentDeliveries: BehaviorSubject<
    DeliveryAddress[]
  > = new BehaviorSubject<DeliveryAddress[]>(null);
  public currentDeliveries$ = this._currentDeliveries as Observable<
    DeliveryAddress[]
  >;
  private _currentOptions: BehaviorSubject<
    CompanyOptions
  > = new BehaviorSubject<CompanyOptions>(null);
  public currentOptions$ = this._currentOptions as Observable<CompanyOptions>;
  protected trackOrder$: Observable<any>;
  trackOrderList: any;

  protected basePath = environment.apiUrl;

  protected readonly basePathConst = this.basePath;
  protected readonly basePathUser = `${this.basePathConst}/secure/users/`;
  protected readonly basePathCompany = `${this.basePathConst}/secure/companies/`;
  protected readonly basePathOrder = `${this.basePathConst}/secure/orders/`;
  protected readonly basePathTrackOrder = `${this.basePathConst}/secure/trackOrderByTrackNumber`;

  constructor(
    private http: HttpClient,
    private authentificationService: AuthentificationService,
    private storage: BrowserStorageService
  ) {
    if (
      this.storage.get("id") !== "5XVwWvlXIKd28s5WloKo" &&
      this.storage.get("isAdmin") === "false"
    ) {
      this.getCompaniesFromAccountId(this.storage.get("id"));
    }
  }

  get Companies() {
    return this._companies.asObservable();
  }
  get PickUpAdCompanies() {
    return this._currentAdress.asObservable();
  }
  get DeliveryAdCompanies() {
    return this._currentDeliveries.asObservable();
  }
  get OptionsCompany() {
    return this._currentOptions.asObservable();
  }
  public getCompaniesFromAccountId(userId: string) {
    this.basePath = this.basePathUser;
    this.http
      .get<any>(this.basePath + userId + "/companies")
      .pipe(
        map(rep => {
          const Acompanies: any[] = rep?.data;
          return Acompanies.map((company: Company) =>
            Company.fromJson(company)
          );
        })
      )
      .subscribe(
        (data: Company[]) => {
          this._companies.next(data);
        },
        err => console.error(err)
      );
  }
  // return options of the current company
  public getCompanieOptionsByCompanyId(companyId: string): any {
    this.Companies.subscribe(companies => {
      let selectedCompany: any = companies.filter(company => {
        if (company.companyId === companyId) return company;
      });
      selectedCompany = selectedCompany[0];
      this._currentOptions.next(
        CompanyOptions.fromJson(selectedCompany?.options)
      );
    });
  }
  public putCompanieOptionsByCompanyId(
    companyId: string,
    body: any
  ): Observable<any> {
    this.basePath = this.basePathCompany;
    return this.http.put<any>(this.basePath + companyId + "/options", body);
  }
  public onGetCompanyAddresses(companyId: string): any {
    this.basePath = this.basePathConst;
    this.http
      .get<any>(
        this.basePath + "/secure/companies/" + companyId + "/pickup-address"
      )
      .pipe(
        map(rep => {
          if (rep) {
            if (rep?.code === 200) {
              const AaddressesP: any[] = rep?.data;
              return AaddressesP.map((company: AddresPickUp) => {
                return AddresPickUp.fromJson(company);
              });
            }
            if (rep?.code === 204) {
              return null;
            }
            if (rep?.code === 401) {
              console.error("JWT error unAutorize!");
              this.authentificationService.logout();
            } else {
              console.error(rep);
            }
          } else {
            console.error(rep);
            throw new Error("Internal server error");
          }
        })
      )
      .subscribe((data: AddresPickUp[]) => {
        this._currentAdress.next(data);
      });
  }
  public onGetDeliveriesAddresses(companyId: string) {
    this.basePath = this.basePathConst;
    this.http
      .get<_respRequest>(
        this.basePath + "/secure/companies/" + companyId + "/delivery-address"
      )
      .pipe(
        map(rep => {
          if (rep) {
            if (rep?.code === 200) {
              const AaddressesP: any[] = rep?.data;
              // We work only with the 10 first elements
              return AaddressesP.slice(0, 10).map(
                (address: DeliveryAddress) => {
                  return DeliveryAddress.fromJson(address);
                }
              );
            }
            if (rep?.code === 204) {
              return null;
            }
            if (rep?.code === 401) {
              console.error("JWT error unAutorize!");
              this.authentificationService.logout();
            } else {
              console.error(rep);
            }
          } else {
            console.error(rep);
            throw new Error("Internal server error");
          }
        })
      )
      .subscribe((data: DeliveryAddress[]) => {
        this._currentDeliveries.next(data);
      });
  }
  public postNewOrder(newOrderObject: any): Observable<any> {
    const url = this.basePathOrder.substring(0, this.basePathOrder.length - 1);
    return this.http.post<any>(url, newOrderObject).pipe(
      tap(rep => {
        return rep;
      })
    );
  }
  // configurer les services plus haut avec les req http et l appel aux services dans les componenets
  setPathUser(): any {
    this.basePath = this.basePathUser;
    return this;
  }
  setPathCompanies(): any {
    this.basePath = this.basePathCompany;
    return this;
  }
  setPathOrder(): any {
    this.basePath = this.basePathOrder;
    return this;
  }
  setPathTrackOrder(): any {
    this.basePath = this.basePathTrackOrder;
    return this;
  }
  // ECOURIER LOGIN SERVICES
  public postLoginEcourier(
    companyId: string,
    login: string,
    password: any
  ): Observable<any> {
    const body = {
      username: login,
      password
    };
    return this.http
      .post<_respRequest>(this.basePath + companyId + "/loginEcourier", body)
      .pipe(
        tap((rep: _respRequest) => {
          if (rep?.code === 401) {
            this.authentificationService.logout();
          }
          return rep?.code === 200;
        })
      );
  }
  // TRACK ORDER SERVICES
  public searchTrackOrder(
    companyId: string,
    trackNumber?: string,
    deliveryName?: string,
    dateFrom?: string,
    dateTo?: string
  ): Observable<any> {
    this.basePath = this.basePathOrder;
    // formating url depending parameter
    let url = this.basePath + companyId + "/trackOrderSearch?";
    if (trackNumber) {
      url = url.concat("trackNumber=" + trackNumber + "&");
    }
    if (deliveryName) {
      url = url.concat("name=" + deliveryName + "&");
    }
    if (dateFrom) {
      url = url.concat("dateFrom=" + dateFrom + "&");
    }
    if (dateTo) {
      url = url.concat("dateTo=" + dateTo + "&");
    }
    url = url.substring(0, url.length - 1);

    return this.http.get<_respRequest>(url).pipe(
      map((rep: _respRequest) => {
        if (rep?.code === 200) {
          const rowsOrderTable: any[] = rep.data;
          return rowsOrderTable; // DTO object?
        }
        if (rep?.code === 204) {
          throw "No data Provided!";
        } else {
          throw new Error("Internal server error!");
        }
      })
    );
  }
  public getOrder(orderNumber: string): Observable<OrderFormData> {
    this.basePath = this.basePathOrder;
    return this.http.get<_respRequest>(this.basePath + orderNumber).pipe(
      map((rep: _respRequest) => {
        if (rep?.code === 200) {
          return OrderFormData.fromJson(rep.data);
        }
        if (rep?.code === 204) {
          throw new Error("No data Provided!");
        } else {
          throw new Error("Internal server error!");
        }
      })
    );
  }
  // ADMIN HOME SERVICES
  public getUserAccountData(accountId: string): Observable<UserAccount> {
    return this.http.get<_respRequest>(this.basePath + accountId).pipe(
      map(rep => {
        if (rep?.code === 200) {
          return UserAccount.fromJson(rep?.data);
        }
        if (rep?.code === 401) {
          this.authentificationService.logout();
        } else {
          throw rep?.code;
        }
      })
    );
  }
  public linkCompanyByCustomerCode(customerCode: string): Observable<any> {
    return this.http
      .get<_respRequest>(
        this.basePath + "searchViaCustomerCode?customerCode=" + customerCode
      )
      .pipe(
        map(rep => {
          if (rep?.code === 204) {
            throw "No data has been found for this number!";
          } else {
            const company: any = rep?.data;
            if (!company.isLink) {
              return company;
            } else {
              return false;
            }
          }
        })
      );
  }
}
