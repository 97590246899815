import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "@env/environment";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { BrowserStorageService } from "./browser-storage.service";
import { Router } from "@angular/router";
import { _respRequest } from "@app/shared/_models/_respRequest.model";

@Injectable({
  providedIn: "root"
})
export class AuthentificationService {
  url: string;
  constructor(
    protected router: Router,
    private http: HttpClient,
    private storage: BrowserStorageService
  ) {
    this.url = environment.apiUrl;
  }

  public toSignIn(email: string, password: string): any {
    const credential = {
      email,
      password
    };

    return this.http.post<any>(this.url + "/signin", credential).pipe(
      map(rep => {
        if (rep.code === 200) {
          // TODO MODEL login User
          // TODO if jwt exist
          const user = rep.data.user;
          const jwt = rep.data.token;
          this.storage.set("jwt", jwt);
          this.storage.set("id", user.id);
          this.storage.set("isAdmin", user.isAdmin);
          return rep;
        }
      }),
      catchError(err => {
        let myError: string;
        console.error(err);
        if (err.status === 404) {
          myError =
            "The login and password you have just entered doesn't match with any account. Please try again!";
        } else {
          myError = "Internal Server Error";
        }
        return throwError(myError);
      })
    );
  }
  public putResetUserPassword(newPassword: string): Observable<any> {
    const userId = this.storage.get("id");
    const body = {
      password: newPassword,
      userId
    };
    return this.http.put<_respRequest>(this.url + "/resetPassword", body).pipe(
      map(resp => {
        if (resp.code === 200) {
          return resp.data["message"];
        } else {
          throw resp.data["message"];
        }
      })
    );
  }

  // logout + delete SessionsStorage
  public logout() {
    this.storage.clear();
    this.router.navigate(["/login"]);
  }
}
