import { Component, Input, ViewChild } from "@angular/core";
import { OrderFormData } from "@app/shared/_models/orderFormData";
import { FormGroup, FormBuilder } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { AccountAndCompaniesService } from "@services/accountAndCompanies/account-and-companies.service";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { DetailsOrderFormComponent } from "../tracking-order-page/details-order-form/details-order-form.component";

@Component({
  selector: "app-details-order-page",
  templateUrl: "./details-order-page.component.html"
})
export class DetailsOrderPageComponent {
  @ViewChild(DetailsOrderFormComponent)
  childOrdFormRef: DetailsOrderFormComponent;
  @Input() currentOrderFormData: OrderFormData;
  orderForm: FormGroup;
  getOrderSub: Subscription;
  orderId: string;
  private currentOrder$: BehaviorSubject<OrderFormData>;

  constructor(
    private fb: FormBuilder,
    private accountServ: AccountAndCompaniesService,
    private router: ActivatedRoute
  ) {
    const paramObject = this.router.snapshot.queryParams;
    this.orderId = paramObject.orderId;

    this.currentOrder$ = new BehaviorSubject<any>(null);
    this.getOrderSub = this.getOrderForm().subscribe(OrderFormData => {
      this.currentOrderFormData = OrderFormData;
      //not the first time because the component is not init
      if (this.childOrdFormRef) {
        this.childOrdFormRef.ngOnInit();
      }
    });

    this.accountServ.getOrder(this.orderId).subscribe(
      dataService => {
        this.setOrderForm(dataService);
      },
      err => {
        console.error(err);
      }
    );
  }

  getOrderForm(): Observable<any> {
    return this.currentOrder$.asObservable();
  }

  public setOrderForm(orderFormData: any): void {
    this.currentOrder$.next(orderFormData);
  }
}
