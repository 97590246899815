import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { AccountAndCompaniesService } from "@app/shared/_services/accountAndCompanies/account-and-companies.service";
import {CompanyOptions, RequiredFields} from "@app/shared/_models/companyOptions.model";
import Utils from "@app/shared/utils/utils";
@Component({
  selector: "app-order-detail",
  templateUrl: "./order-detail.component.html"
})
export class OrderDetailComponent implements OnInit {
  @Output() isValid = new EventEmitter();
  orderDetailForm: FormGroup;
  requiredFields: RequiredFields;

  constructor(
    private fb: FormBuilder,
    private accountServ: AccountAndCompaniesService
  ) {}

  ngOnInit(): void {
    this.orderDetailForm = this.fb.group({
      pieces: [null, Validators.required],
      weight: [0, Validators.required],
      note: [null]
    });

    this.orderDetailForm.valueChanges.subscribe(() => {
      this.isValid.emit(this.orderDetailForm);
    });
    //define Fields required and pattern
    this.accountServ.currentOptions$.subscribe(
      (optionsData: CompanyOptions) => {
        this.requiredFields = optionsData?.requiredFields;
        if (optionsData?.requiredFields.pieces) {
          this.orderDetailForm
            .get(`pieces`)
            .setValidators([
              Validators.pattern(Utils.numberHundredsRegexPattern),
              Validators.required
            ]);
        } else {
          this.orderDetailForm
            .get(`pieces`)
            .setValidators(
              Validators.pattern(Utils.numberHundredsRegexPattern)
            );
        }
        if (optionsData?.requiredFields.weight) {
          this.orderDetailForm
            .get(`weight`)
            .setValidators([
              Validators.pattern(Utils.numberHundredsRegexPattern),
              Validators.required
            ]);
        } else {
          this.orderDetailForm
            .get(`weight`)
            .setValidators(
              Validators.pattern(Utils.numberHundredsRegexPattern)
            );
        }
        this.initFormValues();
      }
    );
  }

  initFormValues(): void {
    const defaultValue = this.requiredFields.weight !== 0 ? "" : 0;
    this.orderDetailForm.get("pieces").setValue("");
    this.orderDetailForm.get("weight").setValue(defaultValue);
    this.orderDetailForm.get("note").setValue("");
  }
}
