<app-header></app-header>
<section class="new-user">
  <form [formGroup]="newUserForm" autocomplete="off">
    <div formGroupName="userDataForm">
      <h2 class="title" *ngIf="!userIdParam">NEW USER</h2>
      <h2 class="title" *ngIf="userIdParam">UPDATE USER</h2>
      <div class="fields-container">
        <mat-form-field class="input-width">
          <mat-label>First Name*</mat-label>
          <input
            matInput
            id="input-firstName"
            type="text"
            formControlName="firstName"
          />
        </mat-form-field>
        <mat-form-field class="input-width">
          <mat-label>Last Name*</mat-label>
          <input
            matInput
            id="input-lastName"
            type="text"
            formControlName="lastName"
          />
        </mat-form-field>
        <mat-form-field class="input-width">
          <mat-label>E-Mail*</mat-label>
          <input
            matInput
            id="input-email"
            type="mail"
            autocomplete="nope"
            formControlName="email"
          />
        </mat-form-field>
        <mat-form-field class="input-width">
          <mat-label>Password*</mat-label>
          <input
            matInput
            id="input-password"
            type="password"
            autocomplete="new-password"
            formControlName="password"
            pattern="^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$"
          />
        </mat-form-field>
        <mat-form-field class="input-width">
          <mat-label>Phone*</mat-label>
          <input matInput id="input-phone" type="tel" formControlName="phone" />
        </mat-form-field>
        <mat-form-field class="input-width">
          <mat-label>Status*</mat-label>
          <mat-select id="input-status" formControlName="status">
            <mat-option value="1">Active</mat-option>
            <mat-option value="0">Disable</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </form>
  <div class="fields-container background">
    <section>
      <mat-toolbar class="mat-toolbar-new-user" style="padding: 0;">
        <span> <b>CLIENTS</b> </span>
      </mat-toolbar>
      <div>
        <p>
          This user doesn't have any ecourrier account linked. <br />You have to
          add link a ecourrier Customer code to this account in order to get all
          orders.
        </p>
      </div>
      <div *ngIf="currentCompaniesA || isAddingValue">
        <div *ngFor="let company of currentCompaniesA; let i = index">
          <div style="padding: 3%;" class="fields-container">
            <div class="input-width">
              <span>
                <b>#{{ i + 1 }}</b>
              </span>
            </div>
            <div class="text-max">
              <mat-form-field
                class="input-width"
                style="width: 100%; margin-bottom: 0;"
              >
                <mat-label>Customer Code</mat-label>
                <input
                  style="margin-top: 5px;"
                  matInput
                  type="number"
                  [value]="company[0].customerCode"
                  disabled
                />
              </mat-form-field>
            </div>
            <div class="text-max">
              <mat-form-field
                class="input-width"
                style="width: 100%; margin-bottom: 0;"
              >
                <mat-label>Customer Name</mat-label>
                <input
                  style="margin-top: 5px;"
                  matInput
                  type="text"
                  [value]="company[0].companyName"
                  disabled
                />
              </mat-form-field>
            </div>
            <button
              mat-mini-fab
              color="primary"
              id="button-delete-client {{ company[0].customerCode }}"
              [disabled]="isAddingValue"
              (click)="deleteACompany(company[0].customerCode)"
            >
              <mat-icon>remove</mat-icon>
            </button>
          </div>
          <div class="line-header"></div>
        </div>
        <div *ngIf="isAddingValue">
          <div class="fields-container">
            <mat-toolbar class="mat-toolbar-new-user" style="padding: 0;">
              <span>Adding a new client </span>
            </mat-toolbar>
            <mat-form-field class="mat-form-field-customer">
              <mat-label>Customer Code</mat-label>
              <input
                matInput
                id="input-customerCode-bis"
                placeholder="12345"
                type="number"
                [disabled]="isCustomIdInputConfirm"
                (keyup)="onkeyUpCustomIdInput($event.target.value)"
              />
              <button
                mat-icon-button
                matSuffix
                color="primary"
                id="button-check"
                [disabled]="!isCustomIdInputCheck()"
                (click)="searchCompanyToLink()"
              >
                <mat-icon>sync</mat-icon>
              </button>
              <mat-hint style="color: #ff5722;" *ngIf="!isAssociationValid()"
                >Check if the code is available</mat-hint
              >
            </mat-form-field>
            <mat-form-field
              class="mat-form-field-customer"
              *ngIf="isCustomIdInputConfirm || isNewClientToAssociate"
            >
              <mat-label>Customer Name</mat-label>
              <input
                matInput
                id="input-customerName-bis"
                placeholder="my client"
                type="text"
                pattern="^(\w)+"
                [disabled]="isAssociationValid()"
                [(ngModel)]="companyName"
                (keyup)="onkeyUpCustomNameInput($event.target.value)"
              />
              <button
                *ngIf="companyName != ''"
                mat-icon-button
                matSuffix
                id="button-check"
                (click)="addRowToArray()"
              >
                <mat-icon style="color: green;">check</mat-icon>
              </button>
              <mat-hint style="color: #ff5722;"
                >Complete a name for this Customer Code</mat-hint
              >
            </mat-form-field>
            <button
              style="margin-right: -35px;"
              mat-mini-fab
              color="primary"
              id="button-cancel-adding-client"
              (click)="closeAddRow()"
            >
              <mat-icon>remove</mat-icon>
            </button>
            <p style="color: red;">{{ errorMessageLink }}</p>
          </div>
        </div>
      </div>
      <mat-toolbar
        *ngIf="!isAddingValue"
        class="mat-toolbar-new-user"
        style="padding: 0;margin: 40px 0;"
      >
        <button
          mat-mini-fab
          color="primary"
          id="button-add-new-client"
          [disabled]="isAddingValue"
          (click)="addRowCompany()"
        >
          <mat-icon>add</mat-icon>
        </button>
        <a (click)="addRowCompany()" style="color: #ff5722;">
          &nbsp; Add a new client
        </a>
      </mat-toolbar>
      <p style="color: red;">{{ errorMessageSubmit }}</p>
      <div class="background-white-absolute">
        <div class="input-width" style="text-align: center;">
          <button
            mat-raised-button
            id="input-submit-new-user"
            class="button submit-button"
            (click)="submitNewUserForm()"
            [disabled]="!this.newUserForm.valid"
            [class.disable-button]="!this.newUserForm.valid"
          >
            SAVE
          </button>
        </div>
      </div>
    </section>
  </div>
</section>
