import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { environment } from "@env/environment";
import { map } from "rxjs/operators";
import { _respRequest } from "@app/shared/_models/_respRequest.model";
import { HttpClient } from "@angular/common/http";
import { ArrayCompanyData } from "../_models/array-company-data";
import { AuthentificationService } from "@app/shared/_services/auth/authentification.service";

//  TODO : Reset servit on submit (vider: _currentCompanies & companiesA)

@Injectable({
  providedIn: "root"
})
export class LinkCompaniesService {
  private companiesA: any[];
  private _currentCompanies: BehaviorSubject<Array<any>> = new BehaviorSubject<
    Array<any>
  >(null);
  protected basePath = environment.apiUrl;

  constructor(
    private http: HttpClient,
    private authentificationService: AuthentificationService
  ) {
    this.companiesA = new Array();
  }

  public onGetCompaniesFromAccountId(accountId) {
    this.http
      .get<_respRequest>(
        this.basePath + "/secure/users/" + accountId + "/companies"
      )
      .pipe(
        map((rep: _respRequest) => {
          if (rep.code == 401) {
            this.authentificationService.logout();
          }
          // api = data{}, code, status
          const Acompanies: any[] = rep.data;
          return Acompanies.map((company: ArrayCompanyData, i: number) => {
            return ArrayCompanyData.fromJson(company, i);
          });
        })
      )
      .subscribe(
        (data: ArrayCompanyData[]) => {
          this._currentCompanies.next(data);
        },
        err => console.error(err)
      );
  }
  get Companies() {
    return this._currentCompanies.asObservable();
  }
  public setCompany(customerCode: any, companyName: string): boolean {
    const newCompany = this.simpleComapnyObjectToArray(
      customerCode,
      companyName
    );
    if (this.isCompDuplicatedByCompIdOrNull(customerCode)) {
      this.companiesA.push(newCompany);
      this._currentCompanies.next(this.companiesA);
      return true;
    } else {
      return false;
    }
  }
  private isCompDuplicatedByCompIdOrNull(customerCodeIncoming): boolean {
    if (customerCodeIncoming) {
      let tmp = !this.companiesA.some(
        company => company[0].customerCode == customerCodeIncoming
      );
      if (tmp) return true;
      else return false;
    }
  }
  public removeCompaniesById(customerCodeIncoming) {
    this.companiesA = this.companiesA.filter((companiesA: any[]) => {
      if (companiesA[0].customerCode != customerCodeIncoming)
        return companiesA[0];
    });
    this._currentCompanies.next(this.companiesA);
  }
  public resetCurrentLinkedCompanies() {
    this.companiesA = new Array();
    this._currentCompanies.next(this.companiesA);
  }
  private simpleComapnyObjectToArray(
    customerCode: number,
    companyName: string
  ): any {
    return [
      {
        customerCode: customerCode,
        companyName: companyName
      }
    ];
  }
}
