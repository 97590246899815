import { Component, OnInit, OnDestroy, ViewChild, Input } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { FormBuilder, FormGroup, FormControl } from "@angular/forms";
import { MatSort } from "@angular/material/sort";
import { Company } from "@app/shared/_models/company.model";
import {BehaviorSubject, Observable, of, Subscription} from "rxjs";
import { AccountAndCompaniesService } from "@app/shared/_services/accountAndCompanies/account-and-companies.service";
import { OrderFormData } from "@app/shared/_models/orderFormData";
import { DetailsOrderFormComponent } from "../details-order-form/details-order-form.component";
import {ActivatedRoute, Router} from "@angular/router";

import * as moment from "moment";


@Component({
  selector: "app-search-table-orders",
  templateUrl: "./search-table-orders.component.html",
  styleUrls: ["./search-table-orders.component.scss"]
})
export class SearchTableOrdersComponent implements OnInit, OnDestroy {
  @Input() selectedCompany: Company;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(DetailsOrderFormComponent)
  childOrdFormRef: DetailsOrderFormComponent;
  myMoment: moment.Moment;

  private myCompany$: BehaviorSubject<Company>;
  private currentOrder$: BehaviorSubject<OrderFormData>;
  currentCompany: Company = null;
  currentOrderFormData: OrderFormData = null;

  companySub: Subscription;
  dataTableSub: Subscription;
  getOrderSub: Subscription;

  dataSource: MatTableDataSource<any>;
  displayedColumns = ["orderId", "date", "customerName"];
  tmpAllTableArray: any[];
  numberRowToDisplay = 5;
  currentIndexStop: number;

  searchOrderForm: FormGroup;

  errorDataTableToDispaly = "";
  public obs$ = null;
  private fragment: string;
  formJustReInit: boolean;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private accountServ: AccountAndCompaniesService
  ) {
    // init subscription && if current Company change
    this.myCompany$ = new BehaviorSubject<Company>(null);

    this.companySub = this.getcompany().subscribe(company => {
      if (this.hasCompanyChanged(company)) {
        this.searchOrderForm.reset();
        this.initSearchForm();
      }
      this.currentCompany = company;
    });
    // init subscription && if current Order Form change
    this.currentOrder$ = new BehaviorSubject<any>(null); // DTO
    this.getOrderSub = this.getOrderForm().subscribe(OrderFormData => {
      this.currentOrderFormData = OrderFormData;
    });
  }
  // Functions to handle if the company selected change
  getcompany(): Observable<Company> {
    return this.myCompany$.asObservable();
  }
  public setCurrentCompany(newComp: Company): void {
    this.myCompany$.next(newComp);
  }
  private hasCompanyChanged(newComp: Company): boolean {
    // first if to check the first init
    if (this.currentCompany) {
      return this.currentCompany?.companyName != newComp?.companyName;
    }
  }
  // Init component
  ngOnInit(): void {
    this.setCurrentCompany(this.selectedCompany);
    this.initSearchForm();
    this.activeRoute.fragment.subscribe(fragment => { this.fragment = fragment; });
  }
  // FORM to search Orders
  private setInitFormValue() {
    this.searchOrderForm = this.fb.group({
      orderId: null,
      deliveryName: "",
      deliveryDate: null,
      beginDateToSend: null,
      endDateToSend: null,
      part1FormAvaible: true,
      part2FormAvaible: true
    });
    this.formJustReInit = true;
    this.searchOrderForm.get("orderId").enable();
    this.searchOrderForm.get("deliveryName").enable();
    this.searchOrderForm.get("deliveryDate").enable();
  }
  private initSearchForm() {
    this.setInitFormValue();
    // At each field change we check if one part have to be desable else we unsubscribe all the form and set as default
    const orderSub = this.searchOrderForm
      .get("orderId")
      .valueChanges.subscribe(() => {
        if (this.searchOrderForm.get("orderId").value != null) {
          this.searchOrderForm.get("part2FormAvaible").setValue(false);
          this.formJustReInit = false;
        }
      });
    const deliveryNameSub = this.searchOrderForm
      .get("deliveryName")
      .valueChanges.subscribe(() => {
        if (
          this.searchOrderForm.get("deliveryName").value != "" ||
          this.searchOrderForm.get("deliveryDate").value != null
        ) {
          this.searchOrderForm.get("part1FormAvaible").setValue(false);
          this.formJustReInit = false;
        }
      });
    const deliveryDateSub = this.searchOrderForm
      .get("deliveryDate")
      .valueChanges.subscribe(() => {
        if (
          this.searchOrderForm.get("deliveryName").value != "" ||
          this.searchOrderForm.get("deliveryDate").value != null
        ) {
          this.searchOrderForm.get("part1FormAvaible").setValue(false);
          this.formJustReInit = false;
        }
      });

    const part1FormAvaibleSub = this.searchOrderForm
      .get("part1FormAvaible")
      .valueChanges.subscribe(() => {
        if (!this.searchOrderForm.get("part1FormAvaible").value) {
          this.searchOrderForm.get("orderId").disable();
        }
        if (
          this.searchOrderForm.get("orderId").value == null &&
          this.searchOrderForm.get("deliveryName").value == "" &&
          this.searchOrderForm.get("deliveryDate").value == null
        ) {
          this.setInitFormValue();
        }
      });
    const part2FormAvaibleSub = this.searchOrderForm
      .get("part2FormAvaible")
      .valueChanges.subscribe(() => {
        if (this.searchOrderForm.get("part1FormAvaible").value) {
          this.searchOrderForm.get("deliveryName").disable();
          this.searchOrderForm.get("deliveryDate").disable();
        }
        this;
      });

    this.searchOrderForm.valueChanges.subscribe(() => {
      if (!this.formJustReInit) {
        if (
          this.searchOrderForm.get("orderId").value == null &&
          this.searchOrderForm.get("deliveryName").value == "" &&
          this.searchOrderForm.get("deliveryDate").value == null
        ) {
          orderSub.unsubscribe();
          deliveryNameSub.unsubscribe();
          deliveryDateSub.unsubscribe();
          part1FormAvaibleSub.unsubscribe();
          part2FormAvaibleSub.unsubscribe();
          this.initSearchForm();
        }
      }
    });
  }
  private isAllInputEnable(): boolean {
    if (
      this.searchOrderForm.get("orderId").enabled &&
      this.searchOrderForm.get("deliveryName").enabled &&
      this.searchOrderForm.get("deliveryDate").enabled
    ) {
      return true;
    }
    else { return false; }
  }
  // handle display active button
  public checkIsButtonFormValid(): boolean {
    if (!this.isAllInputEnable()) { return true; }
    else { return false; }
  }
  // handle loading on search
  private loadingWhenSearching(): void {
    this.router.navigate( ["/trackOrder"], {fragment: "results"} );
    const self = this;
    // tslint:disable-next-line:only-arrow-functions
    setTimeout(function() {
      try {
        document.querySelector("#" + self.fragment).scrollIntoView();
        self.obs$ = of("");
      } catch (e) { console.error(e); }
    }, 400);
  }
  public onSearchOrder() {
    // formated Date
    this.setFormatedDateValueForm();
    // disable older form Order
    this.setOrderForm(null);
    // loading pipe
    this.obs$ = of("ing...");
    // call service Trackorder and display table
    this.dataTableSub = this.accountServ
      .searchTrackOrder(
        this.currentCompany.companyId,
        this.searchOrderForm.get("orderId").value,
        this.searchOrderForm.get("deliveryName").value,
        this.searchOrderForm.get("beginDateToSend").value,
        this.searchOrderForm.get("endDateToSend").value
      )
      .subscribe(
        data => {
          this.tmpAllTableArray = data;
          this.dataSource = new MatTableDataSource(
            this.handleSizeRowTable(false)
          );
          this.isDataTableDisplay();
          this.loadingWhenSearching()
        },
        err => {
          this.isDataTableDisplay();
          this.dataSource = new MatTableDataSource(null);
          this.errorDataTableToDispaly = err;
          console.error(err);
        },
        () => {
          this.obs$ = of("");
        }
      );
  }
  setFormatedDateValueForm() {
    const datesValue = this.searchOrderForm.get("deliveryDate").value;
    if (datesValue != null) {
      const beginDateFormated = datesValue.begin.format("MM/DD/YYYY");
      const endDateFormated = datesValue.end.format("MM/DD/YYYY");
      this.searchOrderForm.get("beginDateToSend").setValue(beginDateFormated);
      this.searchOrderForm.get("endDateToSend").setValue(endDateFormated);
    }
  }
  public isDataTableDisplay() {
    if (this.dataSource) {
      if (this.dataSource.data == null) { return false; }
      return true;
    } else { return false; }
  }
  // 'See More' component wit DataTable
  handleSizeRowTable(fromSeeMore: boolean): any[] {
    this.numberRowToDisplay = fromSeeMore
      ? this.numberRowToDisplay + this.numberRowToDisplay
      : this.numberRowToDisplay;
    let i = 0;
    const tmp = this.tmpAllTableArray.filter(user => {
      if (i < this.numberRowToDisplay) {
        i = i + 1;
        return user;
      }
    });
    this.currentIndexStop = i;
    this.isMoreToSee();
    return tmp;
  }
  onSeeMore() {
    this.dataSource = new MatTableDataSource(this.handleSizeRowTable(true));
  }
  isMoreToSee(): boolean {
    console.log(this.tmpAllTableArray, this.dataSource);
    if (this.tmpAllTableArray?.length == this.dataSource?.data?.length) {
      return false;
    }
    else { return true; }
  }
  // Handle ODER FORM, when a row is clicked in the table
  public onSelectOrder(orderRow: any) {
    this.router.navigate([`orderDetails`], {
      queryParams: {
        orderId: orderRow.id
      }
    });
  }
  getOrderForm(): Observable<any> {
    return this.currentOrder$.asObservable();
  }
  public setOrderForm(orderFormData: any): void {
    this.currentOrder$.next(orderFormData);
  }
  isOrderFormDisplay(inputOrderData: any): boolean {
    if (inputOrderData) { return true; }
    else { return false; }
  }
  ngOnDestroy() {
    this.setCurrentCompany(null);
    this.setOrderForm(null);
  }
}
