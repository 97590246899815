<app-header banner="track"></app-header>
<section class="track-order">
  <h3 class="title">TRACK ORDER</h3>
  <div class="center-align">
    <mat-form-field>
      <mat-label>Client ID</mat-label>
      <mat-select>
        <div *ngFor="let company of companies">
          <mat-option
            id="input-option-company"
            [value]="company.companyName"
            (click)="onSelectedCompany(company)"
            *ngIf="company.companyName"
          >
            {{ company.companyName }}
          </mat-option>
        </div>
      </mat-select>
    </mat-form-field>
  </div>
</section>
<app-search-table-orders
  *ngIf="isCurrentCompanyDisplay"
  [selectedCompany]="selectedCompany"
></app-search-table-orders>
