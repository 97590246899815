<div class="form" *ngIf="this.requiredDeliveryFields">
  <h3 class="form-title">DELIVERY INFO</h3>
  <form [formGroup]="deliveryInfoForm" autocomplete="off">
    <mat-form-field *ngIf="isDataProvided() && this.requiredDeliveryFields?.nameDelivery !== 0" class="input-width">
      <mat-label
        >Delivery Name{{
          deliveryInfoForm | starRequiredInput: "name"
        }}</mat-label
      >
      <input
        type="text"
        id="input-delivery-name"
        placeholder="select or type a new address"
        aria-label="Number"
        matInput
        (click)="displayAutoComplete()"
        formControlName="name"
        [matAutocomplete]="auto"
      />
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option
          *ngFor="let address of filteredAddresses | async"
          id="input-delivery-option"
          (click)="setFormValues(address)"
          [value]="address.name"
        >
          {{ getAddressOptionValue(address) }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <!-- To handle the case with no autoComplete -->
    <mat-form-field *ngIf="!isDataProvided() && this.requiredDeliveryFields?.nameDelivery !== 0" class="input-width">
      <mat-label
        >Delivery Name{{
          deliveryInfoForm | starRequiredInput: "name"
        }}</mat-label
      >
      <input
        type="text"
        id="input-delivery-name"
        placeholder="Ex: My Customer Name "
        aria-label="Number"
        matInput
        (click)="displayAutoComplete()"
        formControlName="name"
        [matAutocomplete]="auto"
      />
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option
          *ngFor="let option of noDataToAutoComplete"
          [disabled]="!isDataProvided()"
          id="input-delivery-option-no-data"
        >
          {{ option }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <mat-form-field class="input-width" *ngIf="this.requiredDeliveryFields?.phoneDelivery !== 0">
      <mat-label
        >Delivery Phone{{
          deliveryInfoForm | starRequiredInput: "phone"
        }}</mat-label
      >
      <input
        matInput
        id="input-delivery-phone"
        type="tel"
        formControlName="phone"
        pattern="(^[0-9]{10}$\-?)+"
      />
    </mat-form-field>
    <mat-form-field class="input-width" *ngIf="this.requiredDeliveryFields?.addressDelivery !== 0">
      <mat-label
        >Delivery address *
      </mat-label>
      <input
        matInput
        type="text"
        id="input-address"
        formControlName="address"
        value=""
        [matAutocomplete]="auto1"
        autocomplete="chrome-off"
      />
      <mat-autocomplete #auto1="matAutocomplete">
        <mat-option *ngFor="let option of optionsAddress" [value]="option.description" (onSelectionChange)="addressSelected(option)">{{option.description}}</mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <mat-form-field class="input-width" *ngIf="this.requiredDeliveryFields?.cityDelivery !== 0" >
      <mat-label
        >Delivery City *
      </mat-label>
      <input
        matInput
        type="text"
        id="input-delivery-city"
        formControlName="city"
        value=""
      />
    </mat-form-field>
    <mat-form-field class="input-width" *ngIf="this.requiredDeliveryFields?.stateDelivery !== 0">
      <mat-label
        >Delivery State *
      </mat-label>
      <input
        matInput
        type="text"
        id="input-delivery-state"
        formControlName="state"
        value=""
        pattern="(^[A-Z]{2}$)+"
      />
    </mat-form-field>
    <mat-form-field class="input-width"  *ngIf="this.requiredDeliveryFields?.zipDelivery !== 0">
      <mat-label
        >Delivery Zip Code *
      </mat-label>
      <input
        matInput
        type="text"
        id="input-delivery-zip"
        formControlName="zip"
        value=""
        pattern="(^[0-9]{5}$)+"
      />
    </mat-form-field>
    <mat-form-field class="input-width" *ngIf="this.requiredDeliveryFields?.complementDelivery !== 0">
      <mat-label
        >Delivery Apt / Room / Floor / Suite{{
          deliveryInfoForm | starRequiredInput: "complement"
        }}
      </mat-label>
      <input
        matInput
        type="text"
        id="input-delivery-complement"
        formControlName="complement"
        value=""
      />
    </mat-form-field>
    <mat-form-field class="input-width" *ngIf="this.requiredDeliveryFields?.seeDelivery !== 0">
      <mat-label
        >Delivery See (on site contact){{ deliveryInfoForm | starRequiredInput: "see" }}
      </mat-label>
      <input
        matInput
        type="text"
        id="input-see"
        formControlName="see"
        value=""
      />
    </mat-form-field>
    <mat-form-field class="input-width" *ngIf="this.requiredDeliveryFields?.emailDelivery !== 0">
      <mat-label
        >Delivery Email{{ deliveryInfoForm | starRequiredInput: "mail" }}
      </mat-label>
      <input
        matInput
        type="email"
        id="input-email"
        formControlName="mail"
        pattern="^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$"
        value=""
      />
    </mat-form-field>
    <mat-form-field class="input-note"  *ngIf="this.requiredDeliveryFields?.noteDelivery !== 0">
      <mat-label
        >Delivery Notes
        {{ deliveryInfoForm | starRequiredInput: "note" }}</mat-label
      >
      <textarea
        matInput
        type="text"
        id="input-delivery-note"
        formControlName="note"
        value=""
      ></textarea>
    </mat-form-field>
  </form>
</div>
