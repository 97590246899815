<app-header banner="none"></app-header>
<section class="reset-page">
  <div class="reset-component">
    <div class="reset-header">
      <h2>Reset your Password</h2>
      <p>Please type a new password.<br /></p>
      <small>
        <i
          >The password must contain at least 8 characters including: <br />
          - A capital letter <br />
          - A number<br />
          - A special Character<br />
        </i>
      </small>
    </div>
    <br />
    <div class="reset-body">
      <mat-form-field style="width: 100%;">
        <mat-label>Password</mat-label>
        <input
          matInput
          id="input-reset-password"
          type="password"
          [formControl]="currentPassword"
          pattern="^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$"
        />
      </mat-form-field>
      <mat-form-field style="width: 100%;">
        <mat-label>Confirm Password</mat-label>
        <input
          matInput
          id="input-reset-confirm"
          type="password"
          [formControl]="bisPassword"
          pattern="^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$"
        />
      </mat-form-field>
      <br />
      <p style="color: red;">{{ errorMessage }}</p>
      <button
        mat-raised-button
        color="primary"
        id="input-button-reset"
        [disabled]="!isPasswordValid()"
        (click)="submitNewPassword()"
      >
        CONFIRM
      </button>
    </div>
  </div>
</section>
