export class OrderInfo {
  constructor(
    public pieces: string,
    public weight: string,
    public note: string
  ) {}
  public static fromJson(json: any): OrderInfo {
    return new OrderInfo(
      json.Pieces,
      json.Weight,
      json.Description
    );
  }
  public static toJson(orderInfo: OrderInfo): any {
    return JSON.stringify(orderInfo);
  }
}
