import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-success-order-page",
  templateUrl: "./success-order-page.component.html"
})
export class SuccessOrderPageComponent {
  orderNumber: string;
  orderId: string;

  constructor(private router: ActivatedRoute) {
    const paramObject = this.router.snapshot.queryParams;
    this.orderNumber = paramObject.orderNumber;
    this.orderId = paramObject.orderId;
  }

  ngOnInit(): void {}
}
