import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";

export const providers = [];

@NgModule({
  imports: [CommonModule, HttpClientModule],
  providers
})
export class ServicesFrontModule {}
