export class UserAccount {
  constructor(
    public email: string,
    public lastName: string,
    public firstName: string,
    public phone: string,
    public status: string,
    public createdAt: string,
    public password = null,
    public isAdmin: boolean = false
  ) {}

  public static fromJson(json: any): UserAccount {
    return new UserAccount(
      json.email,
      json.lastName, //miss camel case back
      json.firstName, //miss camel case back
      json.phone,
      json.status,
      json.createdAt, // status
      "",
      json.isAdmin
    );
  }
  public static toJson(user: UserAccount): Object {
    return JSON.stringify(user);
  }
}
