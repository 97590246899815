<div class="form">
  <h3 class="form-title">DELIVERY DETAILS</h3>
  <form [formGroup]="deliveryDetailForm" autocomplete="off">
    <mat-form-field class="input-width">
      <mat-label
        >Service Type{{
          deliveryDetailForm | starRequiredInput: "service"
        }}</mat-label
      >
      <mat-select
        class="mat-select-value-text"
        formControlName="service"
        id="selector-service-type"
        (selectionChange)="onServiceChange($event.value)"
      >
        <mat-option
          *ngFor="let serv of optionServices; let i = index"
          [id]="'option-service-type-opt' + i"
          [value]="serv.title"
        >
          {{ serv.label || serv.title }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="input-width">
      <mat-label
        >Delivery Date{{ deliveryDetailForm | starRequiredInput: "date" }}
      </mat-label>
      <input
        matInput
        id="input-delivery-date"
        [min]="minDate"
        [matDatepicker]="picker"
        formControlName="date"
        (dateChange)="onDateChange($event)"
        (click)="picker.open()"
        readonly
      />
      <mat-datepicker
        id="input-datepicker"
        #picker
        disabled="false"
      ></mat-datepicker>
      <mat-datepicker-toggle
        matSuffix
        id="input-datepicker-toggle"
        [for]="picker"
      ></mat-datepicker-toggle>
    </mat-form-field>
    <mat-form-field class="input-width">
      <mat-label
        >Delivery Type{{ deliveryDetailForm | starRequiredInput: "type" }}
      </mat-label>
      <mat-select formControlName="type" id="input-delivery-type">
        <mat-option
          a-for
          *ngFor="let type of DTypes; let i = index"
          [id]="'option-delivery-type-opt' + i"
          [value]="type.value"
          >{{ type.name }}</mat-option
        >
      </mat-select>
    </mat-form-field>
    <div class="input-width-time-window">
      <label class="label" style="font-size: small;">Delivery Window*</label>
      <app-time-window
        id="window-select-date"
        *ngIf="isWindowTimeDisplay"
        [selectedDate]="selectedDate"
        [selectedService]="selectedService"
        [minutesDeliveryWindow]="minutesDeliveryWindow"
        (timeWindowFormChange)="onTimeWindowFormChange($event)"
      >
      </app-time-window>
    </div>
  </form>
</div>
