import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges
} from "@angular/core";
import { Validators, FormBuilder, FormGroup } from "@angular/forms";
import * as moment from "moment";

@Component({
  selector: "app-time-window",
  templateUrl: "./time-window.component.html"
})
export class TimeWindowComponent implements OnInit {
  @Input() selectedService: number;
  @Input() minutesDeliveryWindow: any; //not only number because 2hour odd or even
  @Input() selectedDate: Date;
  @Output() timeWindowFormChange = new EventEmitter<any>();

  public windowTimeForm: FormGroup;
  private currentDate: Date = new Date();
  public isMinusToDisplay: boolean = false;
  private isASpecialWindowTime: boolean =
    typeof this.minutesDeliveryWindow == "string";

  constructor(private fb: FormBuilder) {
    this.windowTimeForm = this.fb.group({
      from: [{ value: null, disabled: true }, Validators.required],
      to: [{ value: null, disabled: true }],
      beginCompute: [{ value: null, disabled: true }],
      endCompute: [{ value: null, disabled: true }],
      selectedDate: [{ value: null, disabled: true }]
    });
    this.windowTimeForm.get("beginCompute").valueChanges.subscribe(() => {
      const dateToSend: Date = this.windowTimeForm.get("selectedDate").value;
      const lastFromDate = new Date(
        this.windowTimeForm.get("beginCompute").value
      );
      const timeDateEdited = dateToSend.setHours(
        lastFromDate.getHours(),
        lastFromDate.getMinutes()
      );
      this.timeWindowFormChange.emit(
        moment(new Date(timeDateEdited)).format("YYYY-MM-DD[T]HH:mm:[00]")
      );
      this.isASpecialWindowTime
        ? this.isComputedDateUnderCurrentDate(120)
        : this.isComputedDateUnderCurrentDate(this.minutesDeliveryWindow);
    });
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedDate?.previousValue) {
      if (
        changes.selectedDate.previousValue != changes.selectedDate.currentValue
      )
        this.initTimeForSelectedDate();
    }
    if (changes.selectedService?.previousValue) {
      if (
        changes.selectedService.previousValue !=
        changes.selectedService.currentValue
      )
        this.initTimeForSelectedDate();
    }
  }
  ngOnInit(): void {
    this.isASpecialWindowTime = typeof this.minutesDeliveryWindow == "string";
    this.initTimeForSelectedDate();
  }
  ngAfterViewInit(): void {}
  initTimeForSelectedDate(): void {
    //set initial value into form
    this.windowTimeForm.get("selectedDate").setValue(this.selectedDate);

    //construct the current time
    const currentHours = new Date().getHours();
    const currentMin = new Date().getMinutes();
    this.selectedDate.setHours(currentHours, currentMin);

    //check if the case is ODD/EVEN hour one
    const newCurrentMin = this.isASpecialWindowTime
      ? 120
      : this.minutesDeliveryWindow;

    //In the case we only have hours, we rounded up min to :00 and after handle the hours
    const isHours = newCurrentMin / 60 > 1;
    const addMin = isHours ? 0 : this.selectedDate.getMinutes();

    //check the init case when the selectedDate isASpecialWindowTime
    let addHours;
    if (this.isASpecialWindowTime) {
      //change init hours function is  the cuurent ime is odd or even number
      const currentHour = this.selectedDate.getHours();
      const isCurrentHourOdd: boolean = Boolean(currentHour % 2);
      if (this.minutesDeliveryWindow === "ODD") {
        addHours = isCurrentHourOdd ? 2 : 1;
      } else addHours = isCurrentHourOdd ? 1 : 2;
    } else {
      addHours = addMin === 0 ? 0 : 1;
    }
    const futureDate = this.selectedDate.setHours(
      this.selectedDate.getHours() + addHours,
      addMin
    );
    this.selectedDate = this.parseISOStringToDate(
      new Date(futureDate).toISOString()
    );
    this.initTimes();
  }
  //to compute new Time for
  computeNewTime(
    timeToAdd: number,
    baseDate: Date,
    isAddOrMinus: boolean
  ): number {
    const addHour = Math.floor(timeToAdd / 60);
    const addMin = timeToAdd % 60;
    if (isAddOrMinus) {
      return new Date().setHours(
        baseDate.getHours() + addHour,
        baseDate.getMinutes() + addMin
      );
    } else {
      return new Date().setHours(
        baseDate.getHours() - addHour,
        baseDate.getMinutes() - addMin
      );
    }
  }
  initTimes() {
    const baseDate = this.selectedDate;
    this.windowTimeForm.get("from").setValue(this.stringifyLocalTime(baseDate));

    const currentDateCompute = baseDate.getTime();
    this.windowTimeForm.get("beginCompute").setValue(currentDateCompute);

    const endDateComputing = this.computeNewTime(
      this.selectedService,
      baseDate,
      true
    );
    this.windowTimeForm.get("endCompute").setValue(endDateComputing);

    const futureDate = new Date(endDateComputing);
    this.windowTimeForm.get("to").setValue(this.stringifyLocalTime(futureDate));
  }
  setTimes(newDate: Date, newIntegerDate: number) {
    this.windowTimeForm.get("from").setValue(this.stringifyLocalTime(newDate));

    const currentDateCompute = newIntegerDate;
    this.windowTimeForm.get("beginCompute").setValue(currentDateCompute);

    //compute the new TO time
    let endDateComputing = this.computeNewTime(
      this.selectedService,
      newDate,
      true
    );
    this.windowTimeForm.get("endCompute").setValue(endDateComputing);

    const futureDate = new Date(endDateComputing);
    this.windowTimeForm.get("to").setValue(this.stringifyLocalTime(futureDate));
  }
  // param `isAdd` = false for minus computing
  addOddOrEvenCompute(isAdd: boolean, initHoursToAdd: number): number {
    let computeHour: number;
    const currentHour = this.selectedDate.getHours();
    const isHourOdd: boolean = Boolean(currentHour % 2);
    if (this.minutesDeliveryWindow === "ODD") {
      //check add or minus
      if (isAdd)
        computeHour = isHourOdd
          ? currentHour + initHoursToAdd
          : currentHour + initHoursToAdd + 1;
      else
        computeHour = isHourOdd
          ? currentHour - initHoursToAdd
          : currentHour - initHoursToAdd + 1;

      return this.selectedDate.setHours(
        computeHour,
        this.selectedDate.getMinutes()
      );
    }
    //EVEN case
    else {
      //check add or minus
      if (isAdd)
        computeHour = isHourOdd
          ? currentHour + initHoursToAdd + 1
          : currentHour + initHoursToAdd;
      else
        computeHour = isHourOdd
          ? currentHour - initHoursToAdd + 1
          : currentHour - initHoursToAdd;

      return this.selectedDate.setHours(
        computeHour,
        this.selectedDate.getMinutes()
      );
    }
  }
  onAdd() {
    if (this.isASpecialWindowTime) {
      const futureDate = this.addOddOrEvenCompute(true, 2);

      this.setTimes(new Date(futureDate), futureDate);
      this.isComputedDateUnderCurrentDate(120);
    } else if (this.minutesDeliveryWindow > 0) {
      const addHour = Math.floor(this.minutesDeliveryWindow / 60);
      const addMin = this.minutesDeliveryWindow % 60;
      const futureDate = this.selectedDate.setHours(
        this.selectedDate.getHours() + addHour, // currentHour: this.selectedDate.getHours()
        this.selectedDate.getMinutes() + addMin // currentMin: this.selectedDate.getHours()
      );
      this.setTimes(new Date(futureDate), futureDate);
      this.isComputedDateUnderCurrentDate(this.minutesDeliveryWindow);
    }
  }
  onMinus() {
    if (this.isASpecialWindowTime) {
      const futureDate = this.addOddOrEvenCompute(false, 2);

      this.setTimes(new Date(futureDate), futureDate);
      this.isComputedDateUnderCurrentDate(120);
    } else if (this.minutesDeliveryWindow > 0) {
      const addHour = Math.floor(this.minutesDeliveryWindow / 60);
      const addMin = this.minutesDeliveryWindow % 60;
      const futureDate = this.selectedDate.setHours(
        this.selectedDate.getHours() - addHour,
        this.selectedDate.getMinutes() - addMin
      );
      this.setTimes(new Date(futureDate), futureDate);
      this.isComputedDateUnderCurrentDate(this.minutesDeliveryWindow);
    }
  }
  isComputedDateUnderCurrentDate(timeToSubtracting: number): void {
    if (this.isTheSameDates()) {
      //check if the case is ODD/EVEN hour one
      timeToSubtracting = this.isASpecialWindowTime ? 120 : timeToSubtracting;
      if (timeToSubtracting > 0) {
        const addHour = Math.floor(timeToSubtracting / 60);
        //In the case we only have hours, we rounded the hour up
        const addMin =
          addHour < 1 ? timeToSubtracting % 60 : this.selectedDate.getMinutes();
        const futureDate = new Date().setHours(
          this.selectedDate.getHours() - addHour, //+1 1 because GTM issue
          this.selectedDate.getMinutes() - addMin
        );
        if (futureDate < this.currentDate.getTime())
          this.isMinusToDisplay = true;
        else this.isMinusToDisplay = false;
      }
    } else this.isMinusToDisplay = false;
  }

  isTheSameDates(): boolean {
    if (
      this.currentDate.getDate() === this.selectedDate.getDate() &&
      this.currentDate.getMonth() === this.selectedDate.getMonth() &&
      this.currentDate.getFullYear() === this.selectedDate.getFullYear()
    ) {
      return true;
    } else return false;
  }

  stringifyLocalDate(myDate: Date): string {
    const DD = String(myDate.getDate()).padStart(2, "0");
    const MM = String(myDate.getMonth() + 1).padStart(2, "0");
    const YYYY = myDate.getFullYear();
    return MM + "/" + DD + "/" + YYYY;
  }
  stringifyLocalTime(myDate: Date): string {
    return moment(myDate).format("h:mm a");
  }
  parseISOStringToDate(s): Date {
    var b = s.split(/\D+/);
    return new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]));
  }
  // TODO in pipe
  isADecimalNumber(time): boolean {
    if (Number(time.substring(0, 1)) == 1) {
      return time.substring(1, 2) == ":" ? false : true;
    }
  }
}
