import { Pipe, PipeTransform } from "@angular/core";
import { FormGroup, AbstractControl } from "@angular/forms";

@Pipe({ name: "starRequiredInput" })
export class RequiredInputStarPipe implements PipeTransform {
  transform(form: FormGroup, value: string) {
    const validator = form.get(value).validator
      ? form.get(value).validator({} as AbstractControl)
      : null;
    if (validator) {
      if (validator && validator.required) return "*";
    }
  }
}
