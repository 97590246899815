import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";

import { FeaturesModule } from "@app/shared/_features/features.module";

import { SearchCreateUsersComponent } from "./search-create-account/search-create-users.component";
import { HeaderComponent } from "./header/header.component";
import { ManageAccountComponent } from "./manage-account/manage-account.component";
import { CreateEditAccountAndLinkClientComponent } from "./create-edit-account-and-link-client/create-edit-account-and-link-client.component";

import { MatButtonModule } from "@angular/material/button";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatInputModule } from "@angular/material/input";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { MatDialogModule } from "@angular/material/dialog";
import { MatTableModule } from "@angular/material/table";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatPaginatorModule } from "@angular/material/paginator";
import {MatRadioModule} from '@angular/material/radio';
import {MatIconModule} from '@angular/material/icon';
import {MatAutocompleteModule} from "@angular/material/autocomplete";

const MaterialComponents = [
  MatButtonModule,
  MatGridListModule,
  MatInputModule,
  MatToolbarModule,
  MatFormFieldModule,
  MatSelectModule,
  MatDialogModule,
  MatSnackBarModule,
  MatTableModule,
  MatPaginatorModule,
  MatRadioModule
];

@NgModule({
  declarations: [
    SearchCreateUsersComponent,
    HeaderComponent,
    ManageAccountComponent,
    CreateEditAccountAndLinkClientComponent
  ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialComponents,
        RouterModule,
        MatIconModule,
        MatAutocompleteModule
    ],
  exports: []
})
export class FeaturesBackModule {}
