import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FormControl } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AuthentificationService } from "@app/shared/_services/auth/authentification.service";

@Component({
  selector: "app-reset-password-page",
  templateUrl: "./reset-password-page.component.html"
})
export class ResetPasswordPageComponent {
  currentPassword = new FormControl();
  bisPassword = new FormControl();
  errorMessage: string = "";
  constructor(
    private route: Router,
    private accountCompServ: AuthentificationService,
    private _snackBar: MatSnackBar
  ) {}

  submitNewPassword(): void {
    if (this.currentPassword.value != this.bisPassword.value)
      this.errorMessage = "The passwords are not matching!";
    else
      this.accountCompServ
        .putResetUserPassword(this.currentPassword.value)
        .subscribe(
          msg => {
            this.openSnackBar(msg);
            this.goToHomePage();
          },
          err => {
            this.openSnackBar(err);
            console.error(err);
          }
        );
  }
  goToHomePage() {
    this.route.navigate(["/home"]);
  }
  openSnackBar(text: string) {
    this._snackBar.open(text, "Close", {
      duration: 5000
    });
  }
  isPasswordValid() {
    return (this.currentPassword.value && this.currentPassword.valid && this.bisPassword.valid && this.currentPassword.value === this.bisPassword.value);
  }
}
