import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges
} from "@angular/core";
import { AccountAndCompaniesService } from "@app/shared/_services/accountAndCompanies/account-and-companies.service";
import { FormGroup } from "@angular/forms";
import { DeliveryAddress } from "@app/shared/_models/deliveryAddress.model";
import { Router } from "@angular/router";
import { PickupInfoOptions } from "@app/shared/_models/companyOptions.model";
import { Company } from "@models/company.model";

@Component({
  selector: "app-form-order",
  templateUrl: "./form-order.component.html",
  styleUrls: ["./form-order.component.scss"]
})
export class FormOrderComponent implements OnInit {
  // data & displays
  pickUpData: PickupInfoOptions;
  isPickUpDisplay = false;
  delivery_data: DeliveryAddress[];
  isReadyToSend = true;
  isDeliveryToDisplay = false;
  @Output() OnExpireToken: EventEmitter<any> = new EventEmitter();
  // vars for form
  pickUpForm: FormGroup;
  deliveryInfoForm: FormGroup;
  deliveryDetailForm: FormGroup;
  orderDetailForm: FormGroup;
  @Input() currentCompany: Company;

  constructor(
    private accountCompService: AccountAndCompaniesService,
    private router: Router
  ) {
    // handle all data async here :
    this.pickUpForm = null;
    this.deliveryInfoForm = null;
    this.deliveryDetailForm = null;
    this.orderDetailForm = null;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.currentCompany?.previousValue) {
      if (
        changes.currentCompany.previousValue !=
        changes.currentCompany.currentValue
      ) {
        this.ngOnInit();
      }
    }
  }

  ngOnInit(): void {
    this.initCompanyOptions();
  }

  initCompanyOptions() {
    this.pickUpData = this.currentCompany.options.pickupInfos;
    this.initDeliveryInfoData();
  }

  initDeliveryInfoData() {
    this.accountCompService.currentDeliveries$.subscribe(
      addresses => {
        this.displayPickUpData();
        this.delivery_data = addresses;
        this.isDeliveryToDisplay = true;
      },
      err => {
        console.error(err);
      }
    );
  }

  displayPickUpData() {
    this.isPickUpDisplay = true;
  }

  // functions to send the order
  initObjectToSend() {
    const newOrderObject = [];
    newOrderObject.push(this.pickUpForm.getRawValue());
    newOrderObject.push(this.deliveryInfoForm.value);
    newOrderObject.push(this.deliveryDetailForm.value);
    newOrderObject.push(this.orderDetailForm.value);
    return newOrderObject;
  }

  submitNewOrder() {
    this.isReadyToSend = false;
    this.accountCompService.postNewOrder(this.initObjectToSend()).subscribe(
      response => {
        if (response.code === 200) {
          this.router.navigate([`successOrder`], {
            queryParams: {
              orderId: response.data?.orderId,
              orderNumber: response.data.orderNumber
            }
          });
        }
      },
      err => console.error(err),
      () => {
        this.isReadyToSend = true;
      }
    );
  }

  isFormValid(): boolean {
    // console.log(
    //   "p ",
    //   this.pickUpForm,
    //   " /di ",
    //   this.deliveryInfoForm,
    //   " /df",
    //   this.deliveryDetailForm,
    //   " /od ",
    //   this.orderDetailForm
    // );
    if (
      this.pickUpForm == null ||
      this.deliveryInfoForm == null ||
      this.deliveryDetailForm == null ||
      this.orderDetailForm == null
    ) {
      return false;
    } else {
      return (
        this.pickUpForm.valid &&
        this.deliveryInfoForm.valid &&
        this.deliveryDetailForm.valid &&
        this.orderDetailForm.valid
      );
    }
  }
}
