import { CompanyOptions } from "./companyOptions.model";

export class Company {
  constructor(
    public companyId: string = "",
    public companyName: string,
    public isLoggedEcourier: boolean,
    public customerCode: string = "",
    public options: CompanyOptions = null
  ) {}

  public static fromJson(json: any): Company {
    return new Company(
      json["id"],
      json["companyName"],
      true, // hack pour empecher l'apparition de la popup de login ecourier (modif dasn les api pour utiliser des credentials universels)
      json["customerCode"],
      json.options
    );
  }
  public static toJson(company: Company): Object {
    return JSON.stringify(company);
  }
}
