export class OrderDetails {
  constructor(
    public orderCreatedDateTime: string = null,
    public orderDispatchDateTime: string = null,
    public orderPickUpDateTime: string = null,
    public orderDeliveredDateTime: string = null,
    public podName: string = null,
    public podDateTime: string = null,
    public podSignature: string = null,
    public caller: string = "",
    public companyId: string = ""
  ) {}
  public static fromJson(json: any): OrderDetails {
    return new OrderDetails(
      json.CreateDate,
      json.DispatchDateTime, //in STOPS 1
      json.PickedUpDateTime, //in STOPS 2
      json.DeliveredDateTime,
      json.PodName,
      json.PodDateTime,
      json.PodSignature,
      json.CallerEmail,
      json.companyId
    );
  }
  public static toJson(orderDetails: OrderDetails): any {
    return JSON.stringify(orderDetails);
  }
}
