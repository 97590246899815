export class DeliveryAddress {
  constructor(
    public name: string,
    public phone = "",
    public address: string,
    public zip: string,
    public city: string,
    public state: string,
    public see = "",
    public complement = "",
    public country: string = "",
    public mail = "",
    public note = ""
  ) {}
  public static fromJson(json: any): DeliveryAddress {
    return new DeliveryAddress(
      json.name,
      json.phone,
      json.address,
      json.zip,
      json.city,
      json.state,
      json.see,
      json.complement,
      json.country,
      json.mail
    );
  }

  public static fromOrderJson(json: any): DeliveryAddress {
    return new DeliveryAddress(
      json.Name,
      json.Phone,
      json.Address,
      json.Zip,
      json.City,
      json.State,
      json.See,
      json.Room,
      json.Country,
      "",
      json.Note
    );
  }

  public static toJson(address: DeliveryAddress): any {
    return JSON.stringify(address);
  }
}
