import { Company } from "./company.model";

export class pickupContact {
  constructor(
    public siteId: number,
    public customerCode: number,
    public phone: number,
    public salespersonId: number,
    public see: string,
    public mail: string,
    public companyData: Company
  ) {}
  public static fromJson(json: Object): pickupContact {
    const myCompany = new Company(
      json["userId"],
      json["name"],
      json["isLoggedEcourier"]
    );
    return new pickupContact(
      json["siteId"],
      json["customercode"],
      json["phone"],
      json["salespersonId"],
      json["name"],
      json["email"],
      myCompany
    );
  }
  public static toJson(address: pickupContact): Object {
    return JSON.stringify(address);
  }
}
