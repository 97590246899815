export class ArrayCompanyData {
  constructor(
    public arrayIndex: number,
    public siteId: number,
    public customerCode: number,
    public phone: number,
    public salespersonId: number,
    public contactName: string,
    public mail: string,
    public userId: string,
    public companyName: string,
    public companyId: string
  ) {}
  public static fromJson(json: Object, index: number): ArrayCompanyData {
    return new ArrayCompanyData(
      index,
      json["siteId"],
      json["customercode"],
      json["phone"],
      json["salespersonId"],
      json["companyName"],
      json["email"],
      json["userId"],
      json["name"],
      json["id"]
    );
  }
  public static toJson(address: ArrayCompanyData): Object {
    return JSON.stringify(address);
  }
}
