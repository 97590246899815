import { AddresPickUp } from "./addresPickUp.model";
import { DeliveryAddress } from "./deliveryAddress.model";
import { DeliveryDetails } from "./deliveryDetails";
import { OrderInfo } from "./orderInfo";
import { OrderDetails } from "./orderDetails";

export class OrderFormData {
  constructor(
    public orderNumber: string,
    private pickUp: AddresPickUp,
    private deliveryInfo: DeliveryAddress,
    private deliveryDetails: DeliveryDetails,
    private orderInfo: OrderInfo,
    private orderDetails: OrderDetails
  ) {}

  public static fromJson(json: any): OrderFormData {
    //mapping
    let jsonStop1 = json.Stops[0];
    let jsonStop2 = json.Stops[1];
    let jsonOrderFees = json.OrderFees;
    //mapping subObject
    const mappingPickUp = AddresPickUp.fromOrderJson(jsonStop1);

    const mappingDeliveryInfo = DeliveryAddress.fromOrderJson(jsonStop2);

    const mappingDeliveryDetails = DeliveryDetails.fromJson(json);

    const mappingOrderInfo = OrderInfo.fromJson(json);

    const mappingOrderDetails = OrderDetails.fromJson({
      ...json,
      PodSignature: jsonStop2.PodSign
    });

    //return object formated
    return new OrderFormData(
      json.OrderNumber,
      mappingPickUp,
      mappingDeliveryInfo,
      mappingDeliveryDetails,
      mappingOrderInfo,
      mappingOrderDetails
    );
  }
  public static toJson(company: OrderFormData): Object {
    return JSON.stringify(company);
  }
  public getPickUpInfo() {
    return this.pickUp;
  }
  public getDeliveryInfo() {
    return this.deliveryInfo;
  }
  public getDeliveryDetails() {
    return this.deliveryDetails;
  }
  public getOrderInfo() {
    return this.orderInfo;
  }
  public getOrderDetails() {
    return this.orderDetails;
  }
}
