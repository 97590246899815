import { Injectable } from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "@env/environment";
import {map} from "rxjs/operators";
import {hasOwnProperty} from "tslint/lib/utils";
import {AddressComponentsInfo, AddressInfo} from "@models/googleService.model";

@Injectable({
  providedIn: "root"
})

export class AutocompleteService {

  protected basePath = environment.apiUrl;
  protected readonly basePathConst = this.basePath + "/secure/companies";

  constructor(private http: HttpClient) {}

  public getAddress(address: string) {
    return this.http.get(this.basePathConst + "/getAddressFromGoogle?address=" + address).pipe(map((rep: any) => {
      return rep?.data.predictions;
    }));
  }

  public getAddressInfo(placeId) {
    return this.http.get(this.basePathConst + "/getAddressInfoFromGoogle?placeId=" + placeId);
  }

  public getAddressFromGoogleObject(addressComponent: AddressComponentsInfo[]): Partial<AddressInfo> {
    const types = ["street_number", "route", "locality", "administrative_area_level_1", "country", "postal_code"];
    const address: Partial<AddressInfo> = {};
    for (const info of addressComponent) {
      for (const type of types) {
        if (info.types.includes(type) && info.hasOwnProperty("types")) {
          if (type === "administrative_area_level_1") {
            address[type] = info.short_name;
          } else {
            address[type] = info.long_name;
          }
        }
      }
    }
    return address;
  }
}
