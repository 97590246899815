<div *ngIf="isPickUpDisplay" class="container">
  <section>
    <app-pick-up-form
      [pickUpData]="pickUpData"
      (isValid)="pickUpForm = $event"
    ></app-pick-up-form>
    <app-delivery-info
      [delivery_data]="delivery_data"
      (isValid)="deliveryInfoForm = $event"
    ></app-delivery-info>
    <app-delivery-detail
      [orderOptions]="currentCompany.options"
      (isValid)="deliveryDetailForm = $event"
    ></app-delivery-detail>
    <app-order-detail (isValid)="orderDetailForm = $event"></app-order-detail>
    <div class="submit-container">
      <div class="subContainer">
        <button
          mat-raised-button
          id="button-submit-order"
          type="submit"
          [disabled]="!isFormValid() || isReadyToSend === false"
          [class.disable-button]="!isFormValid() || isReadyToSend === false"
          class="submit-button"
          (click)="submitNewOrder()"
        >
          Submit order
        </button>
      </div>
    </div>
  </section>
</div>
