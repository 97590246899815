import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpHeaders,
  HttpErrorResponse
} from "@angular/common/http";
import { Observable } from "rxjs";
import { BrowserStorageService } from "../auth/browser-storage.service";

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
  url: string;
  constructor(private storage: BrowserStorageService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.url.includes("secure")) {
      request = request.clone({
        setHeaders: {
          accept: "application/json",
          "x-api-key": "test",
          Authorization: "Bearer " + this.storage.get("jwt")
        }
      });
    }
    // if(request.headers)
    else {
      //All the login keys
      request = request.clone({
        setHeaders: {
          accept: "application/json",
          "x-api-key": "test"
        }
      });
    }
    return next.handle(request);
  }
}
