import {Component, OnInit, Input} from "@angular/core";
import {OrderFormData} from "@app/shared/_models/orderFormData";
import {FormGroup, FormBuilder} from "@angular/forms";
import {DELIVERY_TYPE_LABELS, DeliveryDetails} from "@models/deliveryDetails";
import * as moment from "moment";
import {Company} from "@models/company.model";
import {AccountAndCompaniesService} from "@services/accountAndCompanies/account-and-companies.service";
import {CompanyOptions, ServiceType} from "@models/companyOptions.model";
import {OrderInfo} from "@models/orderInfo";

const orderDetailDateFormat = "D MMMM YYYY  h:mm A";

@Component({
  selector: "app-details-order-form",
  templateUrl: "./details-order-form.component.html"
})
export class DetailsOrderFormComponent implements OnInit {
  @Input() currentOrderFormData: OrderFormData;
  orderForm: FormGroup;
  deliveryDetails: DeliveryDetails;
  orderInfo: OrderInfo;
  orderNumber: string;

  constructor(
    private fb: FormBuilder,
    private accountAndCompaniesService: AccountAndCompaniesService
  ) {
  }

  ngOnInit(): void {
    this.initFormValues();
  }

  initFormValues() {
    //data to fill
    this.orderNumber = this.currentOrderFormData.orderNumber;
    const pickup = this.currentOrderFormData.getPickUpInfo();
    const deliveryInfo = this.currentOrderFormData.getDeliveryInfo();
    const deliveryDetails = this.currentOrderFormData.getDeliveryDetails();
    const orderInfo = this.currentOrderFormData.getOrderInfo();
    this.orderInfo = orderInfo;
    const orderDetails = this.currentOrderFormData.getOrderDetails();
    this.accountAndCompaniesService.getCompanieOptionsByCompanyId(
      orderDetails.companyId
    );
    this.accountAndCompaniesService.currentOptions$.subscribe(
      (options: CompanyOptions) => {
        console.log(options);
        this.deliveryDetails = deliveryDetails;

        this.orderForm = this.fb.group({
          //pickup
          pickUp: this.fb.group({
            name: [{ value: pickup.name, disabled: true }],
            phone: [{ value: pickup.phone, disabled: true }],
            address: [{ value: pickup.address, disabled: true }],
            zip: [{ value: pickup.zip, disabled: true }],
            city: [{ value: pickup.city, disabled: true }],
            state: [{ value: pickup.state, disabled: true }],
            complement: [{ value: pickup.complement, disabled: true }],
            see: [{ value: pickup.see, disabled: true }],
            country: [{ value: pickup.country, disabled: true }],
            note: [{ value: pickup.note || " ", disabled: true }]
          }),
          //delivery Info
          deliveryInfo: this.fb.group({
            name: [{ value: deliveryInfo.name, disabled: true }],
            phone: [{ value: deliveryInfo.phone, disabled: true }],
            address: [{ value: deliveryInfo.address, disabled: true }],
            zip: [{ value: deliveryInfo.zip, disabled: true }],
            city: [{ value: deliveryInfo.city, disabled: true }],
            state: [{ value: deliveryInfo.state, disabled: true }],
            see: [{ value: deliveryInfo.see, disabled: true }],
            complement: [{ value: deliveryInfo.complement, disabled: true }],
            country: [{ value: deliveryInfo.country, disabled: true }],
            mail: [{ value: orderDetails.caller, disabled: true }],
            note: [{ value: deliveryInfo.note || " ", disabled: true }]
          }),
          //delivery Details
          deliveryDetails: this.fb.group({
            service: [
              {
                value: this.matchServiceType(
                  options.serviceTypes,
                  deliveryDetails.serviceType
                ),
                disabled: true
              }
            ],
            deliveryType: [
              {
                value: DELIVERY_TYPE_LABELS[deliveryDetails.deliveryType],
                disabled: true
              }
            ]
          }),
          //order form
          orderInfo: this.fb.group({
            pieces: [{ value: orderInfo.pieces, disabled: true }],
            weight: [{ value: orderInfo.weight, disabled: true }],
            note: [{ value: orderInfo.note || " ", disabled: true }]
          }),
          // order details
          orderDetails: this.fb.group({
          Created: [
            {
              value: `${(orderDetails.orderCreatedDateTime ? moment(orderDetails.orderCreatedDateTime).format(
                orderDetailDateFormat
              ) : "")}`,
              disabled: true
            }
          ],
            Dispatch: [
            {
              value: `${(orderDetails.orderDispatchDateTime ? moment(orderDetails.orderDispatchDateTime).format(
                orderDetailDateFormat
              ) : "")}`,
              disabled: true
            }
          ],
          PickUp: [
            {
              value: `${(orderDetails.orderPickUpDateTime ? moment(orderDetails.orderPickUpDateTime).format(
                orderDetailDateFormat
              ) : "")}`,
              disabled: true
            }
            ],
            Delivered: [
            {
              value: `${(orderDetails.orderDeliveredDateTime ? moment(orderDetails.orderDeliveredDateTime).format(
                orderDetailDateFormat
              ) : "")}`,
              disabled: true
            }
            ],
            podName: [{ value: orderDetails.podName, disabled: true }],
            podSign: [{ value: orderDetails.podSignature, disabled: true }]
          })
        });
    });
  }
  matchServiceType(OptionsServiceTypes: ServiceType[], currentServiceType: string) {
    const foundServiceType = OptionsServiceTypes.filter((serviceType: ServiceType) => {
      return serviceType.title === currentServiceType;
    });
    return foundServiceType[0].label;
  }
}
