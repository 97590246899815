<mat-grid-list class="time-window" rows="3" cols="7" rowHeight="40px">
  <form [formGroup]="windowTimeForm">
    <mat-grid-tile colspan="2">
      <label class="label" style="">FROM :</label>
    </mat-grid-tile>
    <mat-grid-tile colspan="1">
      <span
        *ngIf="!isMinusToDisplay"
        id="button-sub-set-window"
        style="color: #ff5722;"
        (click)="onMinus()"
      >
        <b style="cursor: pointer; font-size: 25px;">-</b>
      </span>
    </mat-grid-tile>
    <mat-grid-tile colspan="3">
      <input
        matInput
        style="
        color: #1e2855 !important;
        font-size: 19px;
        margin-left: 10px;"
        id="time-window-label-from"
        [value]="windowTimeForm.get('from').value | formatTime"
        formControlName="from"
      />
    </mat-grid-tile>
    <mat-grid-tile colspan="1">
      <span
        id="button-add-set-window"
        style="color: #ff5722;"
        (click)="onAdd()"
      >
        <b style="cursor: pointer; font-size: 25px;">+</b>
      </span>
    </mat-grid-tile>
    <mat-grid-tile colspan="3">
      <label class="label">TO :</label>
    </mat-grid-tile>
    <mat-grid-tile colspan="4">
      <input
        matInput
        style="
         color: #1e2855 !important;
        font-size: 19px;
        margin-left: 10px;"
        id="time-window-label-to"
        [value]="windowTimeForm.get('to').value | formatTime"
        formControlName="to"
      />
    </mat-grid-tile>
  </form>
  <mat-grid-tile colspan="7" style="overflow: initial;">
    <label class="label" style="font-style: italic; font-size: 12px;"
      >Choose a begining time and the delivery time slot will be automatically
      generated.</label
    >
  </mat-grid-tile>
</mat-grid-list>
