<div class=" search-bar-track background">
  <section class="search-track-orders">
    <div class="subContainer">
      <h3 class="form-title">
        SEARCH BY
      </h3>
      <!-- FORM TO SEARCH -->
      <form [formGroup]="searchOrderForm">
        <mat-form-field class="mat-grid-tile">
          <mat-label>Order Number</mat-label>
          <input
            matInput
            id="input-order-number"
            placeholder="393103"
            type="number"
            formControlName="orderId"
          />
        </mat-form-field>
        <!-- <div class="horizontal-split"></div> -->
        <h3 style="margin-top: unset;">OR</h3>
        <!-- PART 2 FIELDS -->
        <!-- DELIVERY NAME -->
        <mat-form-field class="mat-grid-tile-left">
          <mat-label>Customer Name</mat-label>
          <input
            matInput
            id="input-customer-name"
            type="text"
            placeholder=""
            formControlName="deliveryName"
          />
        </mat-form-field>
        <!-- TODO: -->
        <!-- DATES PICKING -->
        <mat-form-field class="mat-grid-tile-left">
          <input
            matInput
            readonly
            placeholder="Choose a date"
            [satDatepicker]="picker"
            formControlName="deliveryDate"
            (click)="picker.open()"
          />
          <sat-datepicker #picker [rangeMode]="true"></sat-datepicker>
          <sat-datepicker-toggle matSuffix [for]="picker"></sat-datepicker-toggle>
        </mat-form-field>
        <!-- PART 3 SUBMIT SEARCH -->
        <div class="search-orders-button search">
          <button
            mat-raised-button
            color="primary"
            class="submit-button-order"
            id="button-submit-search-order"
            type="submit"
            [disabled]="!checkIsButtonFormValid()"
            [class.disable-button]="!checkIsButtonFormValid()"
            (click)="onSearchOrder()"
          >
            SEARCH{{obs$ | async}}
          </button>
        </div>
      </form>
      <!-- END FORM  -->
    </div>
  </section>
  <section class="track-order-result-container" >
    <div *ngIf="isDataTableDisplay()"  class="table">
      <table mat-table id="results" [dataSource]="dataSource" matSort>
        <!-- ORDER ID Column -->
        <ng-container matColumnDef="orderId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            ORDER NUMBER
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.OrderNumber }}
          </td>
        </ng-container>

        <!-- DATE Column -->
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>DATE</th>
          <td mat-cell *matCellDef="let element">
            {{ element.OrderDate }}
          </td>
        </ng-container>

        <!-- CUSTOMER NAME Column -->
        <ng-container matColumnDef="customerName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            CUSTOMER NAME
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.Stops[1].Name }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          (click)="onSelectOrder(row)"
        ></tr>
        <!-- TODO PAGINATION -->
      </table>

      <!-- SEE MORE -->
      <section>
        <div class="search-orders-button">
          <button
            mat-raised-button
            style="margin-top: 50px;"
            *ngIf="isMoreToSee()"
            class="submit-button-order"
            color="primary"
            id="button-see-more"
            (click)="onSeeMore()"
          >
            SEE MORE
          </button>
        </div>
      </section>
    </div>
    <div *ngIf="!isDataTableDisplay()" style="color: #ff5722;text-align: center">
      <section>
        {{ errorDataTableToDispaly }}
      </section>
    </div>
  </section>
  <app-details-order-form
    *ngIf="currentOrderFormData"
    [currentOrderFormData]="currentOrderFormData"
  >
  </app-details-order-form>
</div>
