import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { MatRadioModule } from "@angular/material/radio";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";

import { FeaturesModule } from "./shared/_features/features.module";
import { ServicesModule } from "./shared/_services/services.module";
import { FeaturesFrontModule } from "./front/_features/features.module";
import { ServicesFrontModule } from "./front/_services/services.module";
//to import
import { FeaturesBackModule } from "./back/_features/features.back.module";
import { ServicesBackModule } from "./back/_services/services.module";

import { SatDatepickerModule, SatNativeDateModule } from "saturn-datepicker";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FeaturesModule,
    ServicesModule,
    FeaturesFrontModule,
    FeaturesBackModule,
    ServicesFrontModule,
    MatRadioModule,
    HttpClientModule,
    SatDatepickerModule,
    SatNativeDateModule,
    BrowserAnimationsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
