<div class="header-section">
  <mat-toolbar class="mat-toolbar-header-back-office">
    <mat-toolbar-row>
        <img
          class="dutch-logo"
          src="assets/logo/dutch_logo.png"
          alt="home"
          routerLinkActive="active-link"
          routerLink="/homeAdmin"
        />
      <span class="spacer"></span>
      <div class="nav">
        <button
          mat-button
          id="nav-create-account"
          routerLinkActive="active-link"
          routerLink="/createAccount"
        >
          NEW USER
        </button>
        <button
          mat-button
          id="nav-home"
          routerLinkActive="active-link"
          routerLink="/homeAdmin"
        >
          USER LIST
        </button>
        <button
          id="nav-logout"
          mat-button
          routerLinkActive="active-link"
          (click)="logout()"
        >
          LOG OUT
        </button>
      </div>
    </mat-toolbar-row>
    <mat-toolbar-row>
      <span class="line-header"></span>
    </mat-toolbar-row>
  </mat-toolbar>
</div>
