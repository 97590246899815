import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from "@angular/router";
import { AuthentificationService } from "./authentification.service";
import { BrowserStorageService } from "./browser-storage.service";

@Injectable({
  providedIn: "root"
})
export class AuthentificationGuard implements CanActivate {
  constructor(
    private authService: AuthentificationService,
    private router: Router,
    private storage: BrowserStorageService
  ) {}

    canActivate(
      next: ActivatedRouteSnapshot,
      state: RouterStateSnapshot): boolean {
      let url: string = state.url;
  
      return this.checkStorage(url);
    }
  
    checkStorage(url: string): boolean {
      if (this.storage.get("id") && this.storage.get("jwt")) { 
          if(url === "/login"){
              if(this.storage.get("isAdmin") === 'true'){
                  this.router.navigate(['/homeAdmin']);
                  return false;
              }
              else{
                this.router.navigate(['/home']);
                return false
              }
          }
          if(this.storage.get("isAdmin") === 'true'){
            if(url=== "/home"){
              this.router.navigate(['/homeAdmin']);
              return false;
            }
            if(url=== "/successOrder"){
              this.router.navigate(['/homeAdmin']);
              return false;
            }

          }
          if(this.storage.get("isAdmin") === 'false'){
            if(url=== "/homeAdmin"){
              this.router.navigate(['/home']);
              return false;
            }
            if(url=== "/createAccount"){
              this.router.navigate(['/home']);
              return false;
            }
            if(url=== "/createAccount/:userId"){
              this.router.navigate(['/home']);
              return false;
            }
            if(url=== "/manageAccount/:companyId"){
              this.router.navigate(['/home']);
              return false;
            }
          }
          return true;
         }
      else if(url === "/login" || url === "/" ){
           return true;
    } else {
      this.authService.logout();
      return false;
    }
  }
}
