import {Component, OnInit, Input} from "@angular/core";
import {AuthentificationService} from "@app/shared/_services/auth/authentification.service";
import {Router} from "@angular/router";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html"
})
export class HeaderComponent implements OnInit {
  @Input() banner: "track" | "home" | "success-order" | "none" = "home";
  myRoute: string;

  constructor(private authentificationService: AuthentificationService,
              private route: Router) {
  }

  ngOnInit(): void {
    this.myRoute = this.route.url;
  }

  logout() {
    this.authentificationService.logout();
  }
}
