import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { AccountAndCompaniesService } from "@app/shared/_services/accountAndCompanies/account-and-companies.service";
import { ActivatedRoute, Router } from "@angular/router";
import { LinkCompaniesService } from "@app/back/_services/link-companies.service";
import { ArrayCompanyData } from "@app/back/_models/array-company-data";
import { AdminService } from "@app/back/_services/admin.service";
import { UserAccount } from "@app/shared/_models/userAccount.model";
import { Observable } from "rxjs";
import Utils from "@app/shared/utils/utils";

@Component({
  selector: "app-create-edit-account-and-link-client",
  templateUrl: "./create-edit-account-and-link-client.component.html",
  styleUrls: ["./create-edit-account-and-link-client.component.scss"]
})
export class CreateEditAccountAndLinkClientComponent
  implements OnInit, OnDestroy {
  companyId: string;
  currentCompaniesA: ArrayCompanyData[];
  newUserForm: FormGroup;
  userIdParam: any;
  isNewClientToAssociate: boolean = false;
  customerCode: number = null;
  companyName: string = "";
  errorMessageLink: string = "";
  errorMessageSubmit: string = "";
  isAddingValue: boolean = false;
  isCustomerCodeCheck: boolean = false;
  isCustomIdInputConfirm: boolean = false;
  newCompanyName: string = "";

  constructor(
    private router: Router,
    private routerA: ActivatedRoute,
    private fb: FormBuilder,
    private accountCompService: AccountAndCompaniesService,
    private linkCompaniesService: LinkCompaniesService,
    private adminService: AdminService
  ) {}

  ngOnInit(): void {
    this.currentCompaniesA = [];
    this.userIdParam = this.getIfUrlParam() || null;

    //init main form
    this.newUserForm = this.fb.group({
      userDataForm: this.fb.group({
        email: [undefined, [Validators.required, Validators.email]],
        lastName: [undefined, Validators.required],
        firstName: [undefined, Validators.required],
        phone: [
          undefined,
          [Validators.required, Validators.pattern(Utils.phoneRegexPattern)]
        ],
        status: ["1", Validators.required],
        password: this.isNewUserPage()
          ? [undefined, Validators.required]
          : [undefined]
      }),
      isClientLinkValid: [undefined, Validators.required]
    });

    //get data companies from service Array
    this.linkCompaniesService.Companies.subscribe(data => {
      if (data) {
        this.currentCompaniesA = data;
        if (this.currentCompaniesA?.length != 0) {
          this.newUserForm.get("isClientLinkValid").setValue(true);
        }
      }
    });
    //Init values & formsfor Edit User
    if (this.userIdParam) {
      //this.accountCompService.getCompaniesFromAccountId(this.userIdParam);
      if (this.userIdParam != null) {
        this.getUserAccountData();
        this.initCompaniesLinked();
      }
    }
    this.companyId = this.routerA.snapshot.queryParams.companyId;
  }
  initCompaniesLinked() {
    this.adminService.getClientsFromAccountId(this.userIdParam).subscribe(
      companiesLinked => {
        companiesLinked.forEach(compLinked => {
          this.linkCompaniesService.setCompany(
            compLinked?.customerCode,
            compLinked?.companyName
          );
        });
      },
      err => console.error(err)
    );
  }
  getIfUrlParam(): any {
    let urlParam = null;
    this.routerA.url.subscribe(url => {
      if (url[1] != null) {
        urlParam = url[1].path;
      } //[1]
    });
    return urlParam;
  }
  get userDataForm(): any {
    return this.newUserForm.get("userDataForm");
  }
  getUserAccountData() {
    const mockAccountId = this.getIfUrlParam();
    if (mockAccountId) {
      this.accountCompService
        .setPathUser()
        .getUserAccountData(mockAccountId)
        .subscribe(
          data => {
            this.initUserDataFormValues(data);
          },
          err => console.error(err)
        );
    }
  }
  //only in edit User
  initUserDataFormValues(userData: UserAccount) {
    this.userDataForm.get("lastName").setValue(userData.lastName || "");
    this.userDataForm.get("firstName").setValue(userData.firstName || "");
    this.userDataForm.get("phone").setValue(userData.phone || "");
    this.userDataForm.get("password").setValue("");
    this.userDataForm.get("email").setValue(userData.email || "");
    this.userDataForm.get("status").setValue(userData.status || "1");
  }

  //function to handle adding company
  addRowToArray() {
    if (
      this.linkCompaniesService.setCompany(this.customerCode, this.companyName)
    ) {
      this.closeAddRow();
    } else {
      this.setCompanyNameToEmpty();
      this.isCustomIdInputCheck();
      this.isCustomIdInputConfirm = false;
      this.errorMessageLink = "This Customer Code is currently used.";
    }
  }

  closeAddRow() {
    this.errorMessageLink = "";
    this.isAddingValue = false;
    this.isCustomIdInputConfirm = false;
    this.newCompanyName = "";
    this.customerCode = null;
    this.isCustomerCodeCheck = false;
    this.isNewClientToAssociate = false;
    this.setCompanyNameToEmpty();
  }

  setCompanyNameToEmpty() {
    this.companyName = "";
  }

  addRowCompany() {
    this.isAddingValue = !this.isAddingValue;
  }

  deleteACompany(customerCode) {
    this.linkCompaniesService.removeCompaniesById(customerCode);
    this.linkCompaniesService.Companies.subscribe(companies => {
      if (companies.length === 0) {
        this.newUserForm.get("isClientLinkValid").setValue(undefined);
      }
    });
  }

  //handle display page
  isNewUserPage(): boolean {
    if (!this.userIdParam) {
      return true;
    } else {
      return false;
    }
  }

  isCustomIdInputCheck(): boolean {
    if (this.customerCode == null || this.isCustomIdInputConfirm) {
      return false;
    } else {
      return true;
    }
  }

  isAssociationValid(): boolean {
    if (this.isCustomIdInputConfirm) {
      return true;
    }
    return false;
  }

  //handle value input data of row
  onkeyUpCustomIdInput(value) {
    this.customerCode = value;
    if (this.customerCode.toString() == "") {
      this.customerCode = null;
    }
    this.isCustomIdInputCheck();
  }

  onkeyUpCustomNameInput(value) {
    this.companyName = value;
    if (this.companyName.toString() == "") {
      this.customerCode = null;
    }
    this.isCustomIdInputCheck();
  }

  //function to link the new row company or not
  searchCompanyToLink() {
    this.accountCompService
      .setPathCompanies()
      .linkCompanyByCustomerCode(this.customerCode.toString())
      .subscribe(
        data => {
          if (data === false) {
            this.setCompanyNameToEmpty();
            this.isAssociationValid();
            this.errorMessageLink =
              "This client is already link to a user. Thank you to change your Customer Code!";
          } else {
            // Good case
            this.errorMessageLink = "";
            this.companyName = data.companyName;
            this.isCustomIdInputConfirm = true;
            this.addRowToArray();
            //snackBar ?
          }
        },
        (error: any) => {
          this.setCompanyNameToEmpty();
          //active IHM input name
          this.newCompanyName = ""; //to not change space is important
          this.isNewClientToAssociate = true;
          // this.errorMessageLink = error;
        }
      );
  }

  // SUBMIT the form and companies row
  submitNewUserForm() {
    console.log("nav: ", this.companyId);
    //case New User
    if (!this.userIdParam) {
      this.adminService
        .postNewUser(this.userDataForm.value, this.currentCompaniesA)
        .subscribe(
          response => {
            const myUserId = response.data.id;
            this.linkCompaniesService.resetCurrentLinkedCompanies();
            console.log("nav: ", this.companyId);
            this.router.navigate([`manageAccount`], {
              queryParams: {
                userId: myUserId,
                companyId: this.companyId
              }
            });
          },
          () => {
            this.errorMessageSubmit =
              "Submit fail, this user already exist, thank you to try again later.";
          }
        );
    } else {
      //case Edit User
      this.adminService
        .putUserById(
          this.userIdParam,
          this.userDataForm.value,
          this.currentCompaniesA
        )
        .subscribe(
          () => {
            this.linkCompaniesService.resetCurrentLinkedCompanies();
            this.router.navigate([`manageAccount`], {
              queryParams: {
                userId: this.userIdParam,
                companyId: this.companyId
              }
            });
          },
          () => {
            this.errorMessageSubmit =
              "Submit fail, this user already exist, thank you to try again later!";
          }
        );
    }
  }

  ngOnDestroy() {
    this.linkCompaniesService.resetCurrentLinkedCompanies();
  }
}
