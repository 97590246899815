<app-header></app-header>
<section class="new-order-section">
  <h3 class="title">NEW ORDER</h3>
  <div class="center-align">
    <mat-form-field class="select-client">
      <mat-label>Client ID</mat-label>
      <mat-select md-no-cache="true" (selectionChange)="selectCompany($event.value)">
        <div *ngFor="let company of companies">
          <mat-option
            id="input-option-company"
            [value]="company"
            *ngIf="company.companyName">
            {{ company.companyName }}
          </mat-option>
        </div>
      </mat-select>
    </mat-form-field>
  </div>
</section>
<app-form-order
  *ngIf="currentCompany"
  [currentCompany]="currentCompany"
></app-form-order>
