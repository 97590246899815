export class UserRowDataTable {
  constructor(
    public companyName: string,
    public status: string,
    public userName: string,
    public createdAt: string,
    public companyId: string,
    public userId: string
  ) {}
  public static fromJson(json: Object): UserRowDataTable {
    let convertStatus = null;
    let convertDate = null;
    if (json) {
      const myStatus = Number(json["status"]);
      convertStatus = myStatus ? "Active" : "Disable" || "N/A";
      convertDate = json["createdAt"].substring(0, 10) || "N/A";
    }
    return new UserRowDataTable(
      json["name"] || "N/A",
      convertStatus,
      json["userName"] || "N/A",
      convertDate,
      json["companyId"] || "N/A",
      json["userId"] || "N/A"
    );
  }
  public static toJson(data: UserRowDataTable): Object {
    return JSON.stringify(data);
  }
}
