import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import {
  FormControl,
  FormGroupDirective,
  NgForm,
  Validators
} from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";
import { AuthentificationService } from "@app/shared/_services/auth/authentification.service";

@Component({
  selector: "app-login-page",
  templateUrl: "./login-page.component.html",
  styleUrls: ["./login-page.component.scss"]
})
export class LoginPageComponent {
  emailFormControl: FormControl;
  password: FormControl;
  hide: boolean;
  matcher: MyErrorStateMatcher;
  isFormValidated: boolean;
  responseLogin: string;

  constructor(
    private router: Router,
    private serviceAuth: AuthentificationService
  ) {
    // init form
    this.emailFormControl = new FormControl("", [
      Validators.required,
      Validators.email
    ]);
    this.password = new FormControl(this.password, Validators.required);
    this.hide = true;
    this.isFormValidated = false;
    this.matcher = new MyErrorStateMatcher();
    this.responseLogin = "";
  }

  onSubmit(): void {
    this.serviceAuth
      .toSignIn(this.emailFormControl.value, this.password.value)
      .subscribe(
        res => {
          if (res.code == 200) {
            //auth admin case redirection
            if (res.isAdmin) {
              this.router.navigate(["/homeAdmin"]);
            } else {
              this.router.navigate(["/home"]);
            }
          }
          this.responseLogin = res.message;
        },
        err => {
          this.responseLogin = err;
          console.error("HTTP Error", err);
        }
      );
  }
}

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}
