import { TestBed } from "@angular/core/testing";

export const DeliveryTypes = [
  {
    name: "Bike",
    value: 2
  },
  {
    name: "Walker",
    value: 3
  },
  {
    name: "Motor Vehicle",
    value: 4
  },
  {
    name: "2 Person Van",
    value: 5
  },
  {
    name: "Box truck",
    value: 6
  },
  {
    name: "Van",
    value: 7
  },
  {
    name: "--Unassigned--",
    value: 0
  }
];
export enum DELIVERY_TYPE {
  BIKE = 2,
  WALKER = 3,
  MOTOR_VEHICULE = 4,
  TWO_PEOPLE_VAN = 5,
  BOX_TRUCK = 6,
  VAN = 7,
  UNASSIGNED = 0
}
export let DELIVERY_TYPE_LABELS = [
  "UNASSIGNED",
  "UNASSIGNED",
  "BIKE",
  "WALKER",
  "MOTOR VEHICULE",
  "2 PEOPLE VAN",
  "BOX TRUCK",
  "VAN"
];

export class DeliveryDetails {
  constructor(
    public serviceType: string,
    public deliveryType: DELIVERY_TYPE = DELIVERY_TYPE.BIKE,
    public timeFrom: Date = null,
    public timeTo: Date = null,
    public OrderDate: Date = null
  ) {}

  public static fromJson(json: any): DeliveryDetails {
    return new DeliveryDetails(
      json.Service,
      json.VehicleTypeId,
      json.Stops[0].ScheduledDateTime,
      json.Stops[1].ScheduledDateTime,
      json.OrderDate
    );
  }
  public static toJson(deliveryDetails: DeliveryDetails): any {
    return JSON.stringify(deliveryDetails);
  }
}
