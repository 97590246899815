<div class="form">
  <mat-expansion-panel class="expansion-custom" (opened)="panelOpenState = true"
                       (closed)="panelOpenState = false">
    <mat-expansion-panel-header>
      <mat-panel-title>PICK UP INFO</mat-panel-title>
      <mat-panel-description *ngIf="!panelOpenState">{{pickUpAddress.name + ', ' + pickUpAddress.address + ', ' + pickUpAddress.state + ', ' + pickUpAddress.zip + ', ' + pickUpAddress.city }}</mat-panel-description>
    </mat-expansion-panel-header>
    <form [formGroup]="pickUpForm" autocomplete="off">
      <div class="fields-container">
        <mat-form-field class="input-width">
          <mat-label
          >Pick Up Name{{ pickUpForm | starRequiredInput: "name" }}</mat-label
          >
          <input
            matInput
            id="input-pickup-name"
            placeholder="Ex. My name"
            type="text"
            value=""
            formControlName="name"
          />
        </mat-form-field>
        <mat-form-field class="input-width">
          <mat-label
          >Pick Up Phone{{ pickUpForm | starRequiredInput: "phone" }}</mat-label
          >
          <input
            matInput
            type="tel"
            id="input-pickup-phone"
            formControlName="phone"
            value=""
            pattern="(^[0-9]{10}$\-?)+"
          />
        </mat-form-field>
        <mat-form-field class="input-width">
          <mat-label
          >Pick Up address{{ pickUpForm | starRequiredInput: "address" }}
          </mat-label>
          <input
            matInput
            type="text"
            id="input-pickup-address"
            formControlName="address"
            value=""
          />
        </mat-form-field>
        <mat-form-field class="input-width">
          <mat-label
          >Pick Up City{{ pickUpForm | starRequiredInput: "city" }}</mat-label
          >
          <input
            matInput
            type="text"
            id="input-pickup-city"
            formControlName="city"
            value=""
          />
        </mat-form-field>
        <mat-form-field class="input-width">
          <mat-label
          >Pick Up State{{ pickUpForm | starRequiredInput: "state" }}
          </mat-label>
          <input
            matInput
            type="text"
            id="input-pickup-state"
            formControlName="state"
            value=""
            pattern="(^[A-Z]{2}$)+"
          />
        </mat-form-field>
        <mat-form-field class="input-width">
          <mat-label
          >Pick Up Zip Code{{
            pickUpForm | starRequiredInput: "zip"
            }}</mat-label
          >
          <input
            matInput
            type="text"
            id="input-pickup-zip"
            formControlName="zip"
            value=""
            pattern="(^[0-9]{5}$)+"
          />
        </mat-form-field>
        <mat-form-field class="input-width">
          <mat-label
          >Pick Up Apt / Room / Floor / Suite #{{
            pickUpForm | starRequiredInput: "complement"
            }}
          </mat-label>
          <input
            matInput
            type="text"
            id="input-pickup-complement"
            formControlName="complement"
            value=""
          />
        </mat-form-field>
        <mat-form-field class="input-width">
          <mat-label
          >Pick Up See (on site contact){{
            pickUpForm | starRequiredInput: "see"
            }}</mat-label
          >
          <input
            matInput
            type="text"
            id="input-pickup-see"
            formControlName="see"
            value=""
          />
        </mat-form-field>

        <mat-form-field class="input-note">
          <mat-label
          >Pick Up Note{{ pickUpForm | starRequiredInput: "note" }}</mat-label
          >
          <textarea
            matInput
            type="text"
            id="input-pickup-note"
            formControlName="note"
            value=""
          >
        </textarea>
        </mat-form-field>
      </div>
    </form>

  </mat-expansion-panel>
</div>
