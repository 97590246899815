<div class="header-section">
  <mat-toolbar
    [ngClass]="{
      'mat-toolbar-header-front-office': banner === 'home',
      'mat-toolbar-header-track-office': banner === 'track',
      'mat-toolbar-header-success-order': banner === 'success-order',
      'mat-toolbar-header-none': banner === 'none',
      'mat-white-toolbar': myRoute === '/resetPasswordUser'
    }"
  >
    <mat-toolbar-row>
      <img
        class="dutch-logo"
        src="assets/logo/dutch_logo.png"
        routerLinkActive="active-link"
        routerLink="/homeAdmin"
      />
      <span class="spacer"></span>
      <div class="nav">
        <button
          mat-button
          id="nav-home"
          routerLinkActive="active-link"
          [routerLinkActiveOptions]="{ exact: true }"
          routerLink="/home"
        >
          PLACE ORDER
        </button>
        <button
          mat-button
          id="nav-track-order"
          routerLinkActive="active-link"
          routerLink="/trackOrder"
        >
          TRACK ORDER
        </button>
        <span>&nbsp;&nbsp;&nbsp;</span>
        <button
          mat-button
          id="nav-reset-password"
          routerLinkActive="active-link"
          routerLink="/resetPasswordUser"
        >
          RESET PASSWORD
        </button>
        <button
          id="nav-logout"
          mat-button
          routerLinkActive="active-link"
          (click)="logout()"
        >
          LOG OUT
        </button>
      </div>
    </mat-toolbar-row>
    <mat-toolbar-row>
      <span class="line-header"></span>
    </mat-toolbar-row>
  </mat-toolbar>
</div>
