import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { LoginPageComponent } from "@features/login-page/login-page.component";
import { NewOrderPageComponent } from "./front/_features/new-order-page/new-order-page.component";
import { TrackingOrderPageComponent } from "./front/_features/tracking-order-page/tracking-order-page.component";
import { SuccessOrderPageComponent } from "./front/_features/new-order-page/success-order/success-order-page.component";
import { SearchCreateUsersComponent } from "./back/_features/search-create-account/search-create-users.component";
import { ManageAccountComponent } from "./back/_features/manage-account/manage-account.component";
import { CreateEditAccountAndLinkClientComponent } from "./back/_features/create-edit-account-and-link-client/create-edit-account-and-link-client.component";
import { AuthentificationGuard } from "./shared/_services/auth/authentification.guard";
import { ResetPasswordPageComponent } from "./front/_features/reset-password-page/reset-password-page.component";
import {DetailsOrderPageComponent} from "./front/_features/details-order-page/details-order-page.component";

const routes: Routes = [
  {
    path: "",
    canActivate: [AuthentificationGuard],
    redirectTo: "/login",
    pathMatch: "full"
  },
  {
    path: "login",
    component: LoginPageComponent,
    canActivate: [AuthentificationGuard]
  },
  {
    path: "home",
    component: NewOrderPageComponent,
    canActivate: [AuthentificationGuard]
  },
  {
    path: "homeAdmin",
    component: SearchCreateUsersComponent,
    canActivate: [AuthentificationGuard]
  },
  {
    path: "trackOrder",
    component: TrackingOrderPageComponent,
    canActivate: [AuthentificationGuard]
  },
  {
    path: "orderDetails",
    component: DetailsOrderPageComponent,
    canActivate: [AuthentificationGuard]
  },
  {
    path: "successOrder",
    component: SuccessOrderPageComponent,
    canActivate: [AuthentificationGuard]
  },
  {
    path: "resetPasswordUser",
    component: ResetPasswordPageComponent,
    canActivate: [AuthentificationGuard]
  },
  {
    path: "createAccount",
    component: CreateEditAccountAndLinkClientComponent,
    canActivate: [AuthentificationGuard]
  },
  {
    path: "createAccount/:userId",
    component: CreateEditAccountAndLinkClientComponent,
    canActivate: [AuthentificationGuard]
  },
  {
    path: "manageAccount",
    component: ManageAccountComponent,
    canActivate: [AuthentificationGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
